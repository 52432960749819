import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const DEFAULT_ELLIPSIS_CHARACTER = '\u00a0…'

export const Shave = (props) => {
    const {
        maxHeight,
        children='',
        tag='p',
        character=DEFAULT_ELLIPSIS_CHARACTER,
        className,
        onClick,
    } = props

    const [ trimmedText, updateTrimmedText ] = useState(children || '')
    const element = useRef(null)

    useEffect(() => {
        if (element && element.current && element.current.offsetHeight > maxHeight) {
            let text = trimmedText.split(' ')
            text.pop()
            text = text.join(' ')

            updateTrimmedText(text)
        }
    }, [ maxHeight, trimmedText, element ])

    const text = children !== trimmedText ? trimmedText + character : trimmedText

    return React.createElement(tag, {
        ref: element,
        title: children,
        onClick,
        className
    }, text)
}

Shave.propTypes = {
    maxHeight: PropTypes.number.isRequired,
    character: PropTypes.string,
    children: PropTypes.string,
    onClick: PropTypes.func,
    tag: PropTypes.string,
    className: PropTypes.string
}

export default React.memo(Shave)
