import { combineReducers } from 'redux'
import { deviceType, deviceWidth, modal } from './ui'
import { account } from './account'
import { user } from './security'
import { signIn } from './message'

import teams from './teams'
import dashboard from './dashboard'
import users from './users'

export const makeRootReducer = () => {
    return combineReducers({
        account,
        user,
        dashboard,
        users,
        teams,
        ui: combineReducers({
            deviceType,
            deviceWidth
        }),
        modal,
        message: combineReducers({
            signIn
        })
    })
}

export default makeRootReducer
