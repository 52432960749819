import { connect } from 'react-redux'
import React, { Component } from 'react'

import { CloseIcon } from '../Icons'

import { closeModal } from '../../actions/ui'

import { classes as CLN } from '../../utils/classNames'

import './Modal.sass'

export class Modal extends Component {

    handleConfirm = () => {
        this.props.handleClose && this.props.handleClose()
        this.props.config
            && this.props.config.onConfirm
            && this.props.config.onConfirm()
    }

    handleClose = () => {
        this.props.handleClose && this.props.handleClose()
        this.props.config
            && this.props.config.onClose
            && this.props.config.onClose()
    }

    render () {

        const { handleClose, handleConfirm } = this

        const {
            isOpen=false,
            config={},
        } = this.props

        const {
            title='',
            body='',
            button='Ok',
            hasConfirmButton=true,
            hasDismissButton=false,
            id=''
        } = config

        return (
            <div className={ CLN('modal', { [id]: !!id, open: isOpen === true, close: isOpen === false }) }>
                <div onClick={ handleClose } className={ CLN([ 'modal', 'overlay' ])}/>
                <div className={ CLN([ 'modal', 'content' ])}>
                    <div
                        onClick={ handleClose }
                        className={ CLN(['modal', 'content', 'close']) }>
                        <CloseIcon />
                    </div>


                    <h3 className={ CLN(['modal', 'content', 'title']) }>
                        { title }
                    </h3>

                    <section className={ CLN(['modal', 'content', 'body']) }>
                        { body }
                    </section>

                    {
                        hasConfirmButton
                            ? (
                                <button
                                    onClick={ handleConfirm }
                                    type="button"
                                    className={ CLN(['modal', 'content', 'confirm']) }>
                                    { button }
                                </button>
                            )
                            : null
                    }

                    {
                        hasDismissButton
                            ? (
                                <button
                                    onClick={ handleClose }
                                    type="button"
                                    className={ CLN(['modal', 'content', 'dismiss']) }>
                                    Annuler
                                </button>
                            )
                            : null
                    }
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        isOpen: (state.modal && state.modal.isOpen === true) || false,
        config: (state.modal && state.modal.config) || undefined
    }),
    dispatch => ({
        handleClose: () => dispatch(closeModal())
    })
)(Modal)
