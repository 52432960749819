import React from 'react'

import { DashboardBox } from '../Dashboard'

import ProductCard from '../../Card/ProductCard'


const BestProductRow = (props={}) => {
    const {
        loading=true,
        minHeight='auto'
    } = props
    const current = props.current || {}

    const products = current.products
        && Array.isArray(current.products.favorites)
        ? current.products.favorites
        : []

    return (
        <div className="row">
            <div className="col-xs-12 col-lg-12">
                <DashboardBox style={{ minHeight }}loading={ loading }>
                    <div className="row center-sm">
                        {
                            products.map(product => {
                                const className = 'dashboard_card dashboard_card--favorite-product'
                                product = { ...product, className }

                                return (
                                    <div key={ 'favorite-product_' + product.id } className="col-xs-12 col-sm-4">
                                        <div className="box">
                                            <ProductCard { ...product } />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </DashboardBox>
            </div>
        </div>
    )
}

export default BestProductRow
