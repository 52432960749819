import PropTypes from 'prop-types'
import React from 'react'

import LinkPreview from './LinkPreview'
import Link from './Link'

import { capitalizeFirst } from '../../utils/stringFormats'
import { classes as CLN } from '../../utils/classNames'

export const filterByRestrictedRoles = (roles, route) => (
    !Array.isArray(route.restrictedRoles)
    || route.restrictedRoles.some(role=> roles.includes(role))
)

const Links = (props={}) => {

    const {
        routes = [],
        className = 'links',
        roles = []
    } = props

    if (!Array.isArray(routes) || routes.length <= 0) {return null}

    return (
        <ul className={ CLN([ className ]) }>
            {routes
                .filter(({ hasMenuLink = true }) => hasMenuLink)
                .filter(filterByRestrictedRoles.bind(this, roles))
                .map(route =>
                    <li
                        key={ 'navlink_' + route.id }
                        title={ `${ capitalizeFirst(route.label || route.id || '') }${ route.isActive ? '' : ' [ bientôt ]' }` }
                        className={ CLN([className, 'item'])}>
                        {
                            route.isActive
                                ? <Link
                                    icon={ route.icon }
                                    id={ route.id }
                                    label={ route.label }
                                    to={ route.to }
                                    exact={ route.exact }
                                    className={ CLN([className, 'item', 'link' ])}
                                />
                                : <LinkPreview
                                    icon={ route.icon }
                                    id={ route.id }
                                    label={ route.label }
                                    to={ route.to }
                                    className={ CLN([className, 'item', 'link' ])}
                                />
                        }
                    </li>
                )}
        </ul>
    )
}

Links.propTypes = {
    routes: PropTypes.array,
    roles: PropTypes.array,
    className: PropTypes.string
}

export default Links
