import moment from 'moment'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { classes as CLN } from '../../utils/classNames'

import 'moment/locale/fr'
import './PeriodPicker.sass'

class PeriodPicker extends PureComponent {
    constructor (props) {
        super(props)

        this.state = {
            months: {},
            isOpen: false
        }

        this.setAvailableMonths = this.setAvailableMonths.bind(this)
        this.handleClickTrigger = this.handleClickTrigger.bind(this)
        this.handleClickOption = this.handleClickOption.bind(this)
        this.forceClose = this.forceClose.bind(this)
    }

    static propTypes = {
        beginning: PropTypes.string,
        selected: PropTypes.string,
        updatePeriod: PropTypes.func
    }

    setAvailableMonths () {
        const {
            beginning = '0000-01-01T00:00:00Z'
        } = this.props

        const now = moment()
        const dateLimit = moment(beginning)
        const months = {
            ['y' + now.format('YYYY')]: [ now.format() ]
        }

        for (let i = 1; i < 12; i++) {
            const currentMonth = now.subtract(1, 'month')

            if (currentMonth.isBefore(dateLimit)) {
                break
            }

            const currentYear = 'y' + currentMonth.format('YYYY')

            months[currentYear] = months[currentYear] || []

            months[currentYear].push(currentMonth.format())
        }

        this.setState({ months })
    }

    handleClickTrigger (shouldOpen) {
        shouldOpen = typeof shouldOpen === 'boolean'
            ? shouldOpen
            : !this.state.isOpen

        if (shouldOpen && shouldOpen !== this.state.isOpen) {
            document && document.addEventListener('click', this.forceClose)
        } else {
            document && document.removeEventListener('click', this.forceClose)
        }

        shouldOpen !== this.state.isOpen && this.setState({ isOpen: shouldOpen })
    }

    forceClose (e = {}) {
        e.target
            && typeof e.target.className === 'string'
            && (/period-picker_select/i).test(e.target.className) === false
            && this.setState({ isOpen: false })
    }

    handleClickOption (value) {
        value && this.props.updatePeriod && this.props.updatePeriod(value)
        value && this.setState({ isOpen: false })
    }

    componentDidMount() {
        this.setAvailableMonths()
        document && document.addEventListener('click', this.forceClose)
    }

    componentWillUnmount() {
        document && document.removeEventListener('click', this.forceClose)
    }

    componentDidUpdate(lastProps) {
        if (lastProps.beginning !== this.props.beginning) {
            this.setAvailableMonths()
        }
    }

    render () {
        const {
            handleClickOption,
            handleClickTrigger
        } = this

        const {
            selected
        } = this.props

        const {
            isOpen=false,
            months={}
        } = this.state

        const currentYear = moment().format('YYYY')

        return (
            <div className={ CLN(['period-picker'], { open: isOpen }) }>

                <button
                    type="button"
                    value={ selected }
                    onClick={ handleClickTrigger }
                    className={ CLN(['period-picker', 'select', 'trigger'])}>

                    { moment(selected).format('MMMM YYYY').replace(currentYear, '') }

                </button>

                <div className={ CLN(['period-picker', 'select', 'dropdown'], { open: isOpen })}>
                    {
                        Object.keys(months).map(year => (
                            <ul
                                key={ 'period_picker_' + year }
                                className={ CLN(['period-picker', 'select', 'dropdown', 'group'])}>

                                <h6>{ year.slice(1) }</h6>

                                { months[year].map(month => (
                                    <li
                                        key={ 'period_picker_' + month }
                                        onClick={ () => handleClickOption(month) }
                                        className={ CLN(['period-picker', 'select', 'dropdown', 'option'], {
                                            selected: moment(selected).format('YYYYMMMM') === moment(month).format('YYYYMMMM')
                                        })}>
                                        { moment(month).format('MMMM') }
                                    </li>
                                ))}

                            </ul>
                        ))
                    }
                </div >

            </div>
        )
    }
}

export default PeriodPicker
