import React, { Component } from 'react'
import ReactTable, { ReactTableDefaults } from 'react-table'
import selectTableHOC from 'react-table/lib/hoc/selectTable'

import TableCheckbox from './TableCheckbox'
import TableUpdate from './TableUpdate'
import { UserDeleteSpan } from './UserDelete'
import { Loader } from './TableLoader'

import { CaretIcon } from '../Icons'

import { classes as CLN } from '../../utils/classNames'


const Table = selectTableHOC(ReactTable)

export const tableHeaderCellHoc = (label, id) => () => (
    <span className={ CLN(['user-table', 'head', 'cell'], id) }>
        { label }
        &nbsp;
        <CaretIcon className={ CLN(['user-table', 'head', 'cell', 'sort']) } />
    </span>
)

export const TableUpdateCell = props => <TableUpdate { ...props } />

const tableDefaultProps = {
    ...ReactTableDefaults,
    selectType: 'checkbox',
    SelectAllInputComponent: TableCheckbox,
    SelectInputComponent: TableCheckbox,
    selectWidth: 40,
    keyField: 'id',
    showPagination: false,
    showPaginationTop: false,
    showPaginationBottom: false,
    showPageSizeOptions: false,
    resizable: false,
    loadingText: '',
    LoadingComponent: Loader,
    columns: [
        {
            Header: tableHeaderCellHoc('Nom', 'fullname'),
            id: 'fullName',
            accessor: d => `${d.firstName} ${d.lastName}`,
            className: CLN(['user-table', 'body', 'cell'], 'fullname'),
            width: 180
        },
        {
            Header: tableHeaderCellHoc('Email', 'email'),
            accessor: 'email',
            className: CLN(['user-table', 'body', 'cell'], 'email')
        },
        {
            Header: tableHeaderCellHoc('Équipe', 'teams'),
            id: 'team',
            accessor: 'team',
            Cell: TableUpdateCell,
            className: CLN(['user-table', 'body', 'cell'], ['teams', 'select']),
            width: 180
        },
        // {
        //     Header: tableHeaderCellHoc('Subvention', 'company-discount'),
        //     id: 'companyDiscountTypes',
        //     accessor: 'companyDiscountType',
        //     Cell: TableUpdateCell,
        //     className: CLN(['user-table', 'body', 'cell'], ['company-discount', 'select'])
        // },
        {
            Header: tableHeaderCellHoc('Rôle', 'role'),
            id: 'role',
            accessor: 'role',
            Cell: TableUpdateCell,
            className: CLN(['user-table', 'body', 'cell'], ['role', 'select']),
            width: 180
        },
        {
            Header: '',
            accessor: '',
            className: CLN(['user-table', 'body', 'cell'], 'delete'),
            Cell: UserDeleteSpan,
            width: 40
        }
    ],
    users: []
}

export class UserTable extends Component {

    constructor (props) {
        super(props)

        this.state = {
            tableProps: {
                ...tableDefaultProps,
                selectAll: this.props.selectAll,
                isSelected: this.props.isSelected,
                toggleAll: this.props.toggleAll,
                toggleSelection: this.props.toggleSelection,
            }
        }
    }

    render() {
        const {
            data=[],
            isLoading = true,
        } = this.props

        const {
            tableProps
        } = this.state

        return (
            <Table
                { ...tableProps }
                loading={ isLoading }
                pageSize={ data.length }
                data={ !isLoading ? data : [] }
                noDataText={ isLoading ? '' : 'Aucun utilisateur trouvé' }
                className={ CLN('user-table') + ' -highlight' + (isLoading ? ' -isLoading' : '') }
            />
        )
    }
}

export default UserTable
