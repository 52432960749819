import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import {
    Rectangle
} from 'recharts'

import StarIcon from './StarIcon'

import './Bars.sass'

export const BAR_SHAPE_RADIUS = [3,3,0,0]

export const BarShape = (props) => {
    props = { ...props, radius: BAR_SHAPE_RADIUS }

    if (props.value === props.maxValue) {
        props.className = 'bar-chart_starred-bar'
        props.starred = true
    }

    return (
        <Fragment>
            <Rectangle { ...props } />
            <StarIcon { ...props } />
        </Fragment>
    )
}

BarShape.propTypes = {
    value: PropTypes.number,
    maxValue: PropTypes.number,
    maxBarSize: PropTypes.number
}

export default React.memo(BarShape)
