import URL from './urls'

export const SIGN_IN_PARAMS = {
    method: 'POST',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/json; charset=utf-8'
    }
}

export const signIn = (username='', password='') => {
    const params = {
        ...SIGN_IN_PARAMS,
        body: JSON.stringify({ username, password })
    }
    return fetch(URL.signIn, params).then(res => res.json())
}
