import jwt from 'jsonwebtoken'

import URL from './urls'

const getFetchParams = {
    method: 'GET',
    mode: 'cors',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*'
    },
}

const postFetchParams = {
    ...getFetchParams,
    headers: {
        ...getFetchParams.headers,
        'Content-Type': 'application/json',
    },
    method: 'POST'
}

const putFetchParams = {
    ...getFetchParams,
    headers: {
        ...getFetchParams.headers,
        'Content-Type': 'application/json',
    },
    method: 'PUT'
}

const deleteFetchParams = {
    ...getFetchParams,
    headers: {
        ...getFetchParams.headers,
        'Content-Type': 'application/json',
    },
    method: 'DELETE'
}

export const getTeamData = (company, token='') => {
    const tokenData = jwt.decode(token) || {}
    company = company || tokenData.id_company || ''

    const url =  URL.getTeamData.replace(':company', company)

    const params = {
        ...getFetchParams,
        headers: {
            ...getFetchParams.headers,
            'Authorization': 'Bearer ' + token
        }
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
        .then(json => {
            if (!json || typeof json !== 'object') { return {} }

            if (json.code && json.code !== 200) {
                return Promise.reject(json)
            }

            return json
        })
}

export const createTeam = (newTeam, token='') => {
    const url = URL.createTeam

    const tokenData = jwt.decode(token) || {}
    const company = tokenData.id_company || ''

    if (!newTeam) { return }

    const team = {
        ...newTeam,
        company: '/b2b/companies/' + company,
        team_type: '/b2b/company-team-types/' + newTeam.team_type,
        managers: newTeam.managers.map(( item ) => '/customers/' + item.id)
    }

    const params = {
        ...postFetchParams,
        headers: {
            ...postFetchParams.headers,
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(team)
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
        .then(json => {
            if (!json || typeof json !== 'object') { return {} }

            if (json.code && json.code !== 200) {
                return Promise.reject(json)
            }

            const data = Array.isArray(json) && json.length
                ? json[0].data
                : json.data

            return data || {}
        })
}

export const getTeamTypes = (token='') => {
    const url = URL.getTeamTypes

    const params = {
        ...getFetchParams,
        headers: {
            ...getFetchParams.headers,
            'Authorization': 'Bearer ' + token
        }
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
}

export const getFilterCustomers = (searchTerm='', company, token='') => {
    const tokenData = jwt.decode(token) || {}
    company = company || tokenData.id_company || ''

    let url =  URL.getFilterCustomersTeamData.replace(':id', company)
    url = url.replace(':search', searchTerm)

    const params = {
        ...getFetchParams,
        headers: {
            ...getFetchParams.headers,
            'Authorization' : 'Bearer ' + token
        }
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : []
        })
}

export const deleteTeam = (idTeam, token='') => {
    const url = URL.deleteTeam.replace(':id', idTeam)

    const params = {
        ...deleteFetchParams,
        headers: {
            ...deleteFetchParams.headers,
            'Authorization': 'Bearer ' + token
        }
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? Promise.resolve({})
                : Promise.reject({ code: response.status })
        })
}

export const updateTeam = (teamData, token='') => {
    const tokenData = jwt.decode(token) || {}
    const company = tokenData.id_company || ''

    const team = {
        ...teamData,
        company: '/b2b/companies/' + company,
        team_type: '/b2b/company-team-types/' + teamData.team_type,
        managers: teamData.managers.map(( item ) => '/customers/' + item.id)
    }

    const url = URL.updateTeam.replace(':id', team.id)

    const params = {
        ...putFetchParams,
        headers: {
            ...putFetchParams.headers,
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(team)
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
}
