import React from 'react'
import {
    Redirect,
    Route
} from 'react-router-dom'

import SignIn from '../App/SignIn'

export const SignInRoute = ({ user={}, path='/login', exact=true }) => (
    <Route
        exact={ !!exact }
        path={ path }
        render={
            props => {
                const pathname = (props.location
                    && props.location.state
                    && props.location.state.from
                    && props.location.state.from.pathname)
                    || '/'

                return user && user.token
                    ? <Redirect to={{ pathname }} />
                    : <SignIn />
            }
        }
    />
)

export default SignInRoute
