import PropTypes from 'prop-types'
import React from 'react'

import { StarIcon } from '../Icons'
import Shave from '../Text/Shave'

import { classes as CLN } from '../../utils/classNames'
import { getProductImageUrl } from '../../utils/images'

import './ProductCard.sass'

export const PRODUCT_CARD_TAGS = {
    STARTER: 'entrée préférée',
    MAIN_COURSE: 'plat préféré',
    DESSERT: 'dessert préféré'
}

export const MAX_NAME_HEIGHT = 40

const ProductCard = (props={}) => {
    const {
        id='',
        name='',
        type='',
        className=''
    } = props

    const img = getProductImageUrl(id, props.img)

    return (
        <figure className={ className + ' ' + CLN(['product-card'])}>
            <img src={ img } alt={ name } className={ CLN(['product-card', 'img'])} />
            <figcaption className={ CLN(['product-card', 'body'])}>
                <p className={ CLN(['product-card', 'body', 'tag'])}>
                    <StarIcon className={ CLN(['product-card', 'body', 'tag', 'star'])} />
                    { PRODUCT_CARD_TAGS[type] || type }
                </p>
                <p className={ CLN(['product-card', 'body', 'name'])}>
                    <Shave tag="span" maxHeight={ MAX_NAME_HEIGHT }>
                        { name }
                    </Shave>
                </p>
            </figcaption>
        </figure>
    )

}

ProductCard.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    img: PropTypes.string
}

export default React.memo(ProductCard)
