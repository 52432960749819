import {
    AUTHORIZE_ACTION,
    SIGN_IN_ACTION,
    SIGN_OUT_ACTION
} from '../actions/security'

export const user = (state = {}, action) => {
    let newState

    switch (action.type) {
    case AUTHORIZE_ACTION:
    case SIGN_IN_ACTION:
        newState = action.user
        break
    case SIGN_OUT_ACTION:
        newState = {}
        break
    default:
        newState = { ...state }
    }


    return newState
}
