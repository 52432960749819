/* global window */
import URLS from '../data/urls.json'

let urls = { ...URLS.absolute }
const ENV = (window && window.__ENV__ && window.__ENV__.STAGE) || 'master'

urls.cookieDomain = URLS.cookieDomain[ENV] || URLS.cookieDomain.master

Object.keys(URLS.relative)
    .map((key) => (
        urls[key] = (URLS.prefix[ENV] || URLS.prefix.master) + URLS.relative[key]
    ))

export default urls
