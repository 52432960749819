import React from 'react'

import './NoChart.sass'

const NoChart = ({ className = '' }) => (
    <div className={(className + ' no-chart').trim()}>
        Pas de graphique à afficher<br/>pour cette période
    </div>
)

export default React.memo(NoChart)
