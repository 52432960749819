import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import GrowingNumber from '../Animations/GrowingNumber'

import {
    toCurrencyString,
    toPercentageString
} from '../../utils/stringFormats'

import { classes as CLN } from '../../utils/classNames'

const Value = ({ value, type='', symbol='', baseClass='value' }) => {

    if (!value && value !== 0) {
        value = '--'
        type = 'raw'
    }

    switch (type) {
    case 'currency':
        symbol = symbol || '€'
        value = toCurrencyString(value)
        break
    case 'percentage':
        symbol = symbol || '%'
        value = toPercentageString(value).replace(',00', '')
        break
    case 'raw':
    default:
        value = String(value).replace('.', ',')
        break
    }

    if (symbol) { value = value.replace(symbol, '') }

    return (
        <Fragment>
            <span className={ CLN([baseClass, 'units']) }>
                { type === 'raw' ? value.split(',')[0] : <GrowingNumber target={ value.split(',')[0] } /> }
            </span>
            {
                value.split(',')[1]
                    ? <span className={ CLN([baseClass, 'cents']) }>
                        ,{ value.split(',')[1].substr(0, 2) }
                    </span>
                    : null
            }
            {
                symbol
                    ? <span className={ CLN([baseClass, 'symbol']) }>
                        { symbol }
                    </span>
                    : null
            }
        </Fragment>
    )
}

Value.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    type: PropTypes.string,
    symbol: PropTypes.string,
    baseClass: PropTypes.string
}

export default React.memo(Value)
