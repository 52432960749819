import React from 'react'

import { DashboardBox } from '../Dashboard'

import Card from '../../Card/Card'
import ChartCard from '../../Card/ChartCard'

import BarChart from '../../Charts/Bars'
import ProgressImage from '../../Charts/ProgressImage'

import { sum, ratio } from '../../../utils/statistics'

import leavesMaskImage from '../../../images/leaves-mask.svg'

const ProductHabitsRow = (props={}) => {
    const {
        loading=true,
        minHeight='auto'
    } = props

    const current = props.current || {}
    const previous = props.previous || {}

    const veggieRatio = {
        currentRatio: current.products && current.products.count
            ? Math.round(ratio(
                sum([
                    current.products.tags.vegan,
                    current.products.tags.veggie
                ]),
                current.products.count,
                true))
            : 0,
        previousRatio: previous.products && previous.products.count
            ? Math.round(ratio(
                sum([
                    previous.products.tags.vegan,
                    previous.products.tags.veggie
                ]),
                previous.products.count,
                true))
            : 0,
        footer: current.products && current.products.count
            ? `${
                current.products.tags.veggie
                    ? Math.round(ratio(current.products.tags.veggie, current.products.count, true))
                    : 0
            }% végétarien - ${
                current.products.tags.vegan
                    ? Math.round(ratio(current.products.tags.vegan, current.products.count, true))
                    : 0
            }% végétal - ${
                current.products.tags.meat
                    ? Math.round(ratio(current.products.tags.meat, current.products.count, true))
                    : 0
            }% viandes - ${
                current.products.tags.fish
                    ? Math.round(ratio(current.products.tags.fish, current.products.count, true))
                    : 0
            }% poissons`
            : '',
        currentValue: (
            current.products && sum([
                current.products.tags.vegan,
                current.products.tags.veggie
            ]))
            || 0
    }

    const productCount = current.products && current.products.count

    const productTypes = current.products && Array.isArray(current.products.types)
        ? current.products.types
        : []

    const menuCount = (current.products && current.products.menus) || 0

    return (
        <div className="row">
            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }}loading={ loading }>
                    <Card
                        info="veggie-ratio"
                        currentValue={ veggieRatio.currentRatio }
                        previousValue={ veggieRatio.previousRatio }
                        type="percentage"
                        footer={ veggieRatio.footer }
                        className="dashboard_card dashboard_card--veggie-ratio"
                        label="Alimentation végétale">
                        <ProgressImage
                            className="dashboard_card_chart dashboard_card_chart--veggie-ratio"
                            value={ veggieRatio.currentValue }
                            max={ productCount }
                            src={ leavesMaskImage }
                        />
                    </Card>
                </DashboardBox>
            </div>
            <div className="col-xs-12 col-lg-8">
                <DashboardBox style={{ minHeight }}loading={ loading }>
                    <ChartCard
                        className="dashboard_card dashboard_card--product-types"
                        label="Habitudes d'achat"
                        info="product-types"
                        highLightLabel="Menus du jour"
                        highLightValue={ menuCount }>
                        <BarChart
                            data={ productTypes }
                            xDataKey={ 'name' }
                            className="order-count_card_chart" />
                    </ChartCard>
                </DashboardBox>
            </div>
        </div>
    )
}

export default ProductHabitsRow
