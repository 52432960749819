import _ from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'
import React, { Component, Fragment } from 'react'
import PageNotFound from '../App/PageNotFound'

import Lazy from '../Lazy/Lazy'

import CompanyLogo from '../MainMenu/Head'
import Title from '../App/Title'

import PeriodPicker from '../PeriodPicker/PeriodPicker'

import GlobalSection from './GlobalSection'
import ActivitySection from './ActivitySection'
import HabitSection from './HabitSection'

import { capitalizeFirst, obfuscate } from '../../utils/stringFormats'
import { getMonthDateRange, getIsCurrentPeriodOngoing } from '../../utils/datetime'
import { classes as CLN } from '../../utils/classNames'
import { getDashboardData } from '../../actions/dashboard'
import { setAccount } from '../../actions/account'

import 'flexboxgrid/dist/flexboxgrid.min.css'
import './Dashboard.sass'

import ROLES from '../../data/roles'

export const DEFAULT_PERIOD = getMonthDateRange(moment().format())

export const DashboardBox = (props = {}) => {
    const {
        children = null,
        loading = true
    } = props

    const style = loading && props.style ? props.style : undefined

    return (
        <div style={ style } className={ CLN('box', { loading }) }>
            { !loading
                ? <Lazy offset={ 0 }>{ children }</Lazy>
                : null }
        </div>
    )
}

export class Dashboard extends Component {

    constructor (props) {
        super(props)

        this.state = {
            currentPeriod: DEFAULT_PERIOD,
            isCurrentPeriodOngoing: getIsCurrentPeriodOngoing(),
            loading: true
        }

        this.updatePeriod = this.updatePeriod.bind(this)
        this.getDashboardData = this.getDashboardData.bind(this)
    }

    componentDidMount() {
        this.props.token && this.getDashboardData()
    }

    componentDidUpdate(lastProps, lastState) {
        if (!_.isEqual(this.props.match, lastProps.match)) {
            return this.getDashboardData()
        }

        if (this.props.token !== lastProps.token) {
            return this.getDashboardData()
        }

        if (this.props.token && this.state.currentPeriod.start !== lastState.currentPeriod.start) {
            this.getDashboardData()
        }
    }

    getDashboardData() {
        let companyId = (this.props.match
            && this.props.match.params
            && this.props.match.params.companyId)
            || ''

        companyId = obfuscate.parse(companyId) || ''

        this.state.currentPeriod.start
            && this.props.getDashboardData(this.state.currentPeriod, companyId)
    }

    updatePeriod(date) {
        date
            && moment(date).isValid()
            && this.setState({
                currentPeriod: getMonthDateRange(date),
                isCurrentPeriodOngoing: getIsCurrentPeriodOngoing(date)
            })
    }

    render() {

        const {
            currentPeriod,
            isCurrentPeriodOngoing=false
        } = this.state

        const {
            account = null,
            data = {},
            isLoading = true,
            roles = []
        } = this.props

        const {
            updatePeriod
        } = this

        const {
            period: {
                current = null,
                previous = null
            } = {},
            global = null
        } = data

        const selectedPeriod = moment(currentPeriod.start).format()

        if (isLoading === false && !current && !global) {
            return <PageNotFound />
        }

        return (
            <section id="dashboard">

                { account && account.name
                    ? <Title title={ 'Dashboard de ' + account.name } />
                    : null }

                { account && account.logo
                    ? <div className="dashboard_company-logo">
                        <CompanyLogo logo={ account.logo } name={ account.name } />
                    </div>
                    : null }

                <div className="dashboard_body">

                    { roles && (roles.includes(ROLES.ROLE_DASHBOARD_B2B_MANAGER.id) || roles.includes(ROLES.ROLE_SUPER_ADMIN.id))
                        ?
                        <Fragment>
                            <GlobalSection
                                loading={ global === null }
                                data={ global }
                            />

                            <div className="dashboard_period-picker_wrapper">

                                <h3 className="dashboard_section-title">
                                    Activité de l'équipe en {
                                        capitalizeFirst(
                                            moment(selectedPeriod)
                                                .format('MMMM YYYY')
                                                .replace(moment().format('YYYY'), '')
                                        )
                                    }
                                </h3>

                                <PeriodPicker
                                    beginning={ account && account.createdAt }
                                    selected={ selectedPeriod }
                                    updatePeriod={ updatePeriod }
                                />

                            </div>

                            <ActivitySection
                                current={ current }
                                previous={ isCurrentPeriodOngoing ? null : previous }
                                loading={ isLoading }
                            />
                        </Fragment>
                        : null
                    }

                    <h3 className="dashboard_section-title">
                        Vos habitudes de consommation
                    </h3>

                    <HabitSection
                        current={ current }
                        previous={ isCurrentPeriodOngoing ? null : previous }
                        loading={ isLoading }
                    />

                </div>
            </section>
        )
    }
}

export default connect(
    state => ({
        account: state.account || {},
        token: (state.user && state.user.token) || '',
        roles: (state.user && state.user.roles) || [],
        data: (state.dashboard && state.dashboard.data) || {},
        isLoading: state.dashboard && state.dashboard.isLoading
    }),
    dispatch => ({
        updateAccount: (account) => dispatch(setAccount(account)),
        getDashboardData: (period, company) => dispatch(getDashboardData(period, company))
    })
)(Dashboard)
