import _ from 'lodash'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import PageNotFound from '../App/PageNotFound'
import Title from '../App/Title'
import UserHead from './UserHead'
import UserTable from './UserTable'

import ROLES from '../../data/roles'

import { obfuscate } from '../../utils/stringFormats'
import { classes as CLN } from '../../utils/classNames'
import { getUserData } from '../../actions/users'
import { getTeamData } from '../../actions/teams'
import { setAccount } from '../../actions/account'

import 'flexboxgrid/dist/flexboxgrid.min.css'
import 'react-table/react-table.css'
import './Users.sass'

export class Users extends Component {

    constructor (props) {
        super(props)

        this.state = {
            selection: [],
            selectAll: false,
            searchTerm: '',
            users: (this.props.users && this.props.users.map(user => ({ ...user }))) || []
        }

        this.getUserData = this.getUserData.bind(this)
        this.filterData = this.filterData.bind(this)
        this.toggleSelection = this.toggleSelection.bind(this)
        this.toggleAll = this.toggleAll.bind(this)
        this.isSelected = this.isSelected.bind(this)
    }

    componentDidMount() {
        this.props.token && this.getUserData()
    }

    componentDidUpdate(lastProps, lastState) {
        if (!_.isEqual(this.props.users, lastProps.users)) {
            this.toggleAll(false)
            this.setState({
                users: (this.props.users && this.props.users.map(user => ({ ...user }))) || []
            })
        }

        if (lastState.searchTerm !== this.state.searchTerm) {
            this.setState({
                selectAll: false,
                selection: []
            })
        }

        if (!_.isEqual(this.props.match, lastProps.match)) {
            return this.getUserData()
        }

        if (this.props.token !== lastProps.token) {
            return this.getUserData()
        }

    }

    getUserData() {
        let companyId = (this.props.match
            && this.props.match.params
            && this.props.match.params.companyId)
            || ''

        companyId = obfuscate.parse(companyId) || ''

        this.props.getUserData(companyId)
        this.props.getTeamData(companyId)
    }

    filterData ({ target }) {
        const searchTerm = target.value
        if (searchTerm) {
            const regExp = new RegExp(searchTerm, 'i')
            const users = this.props.users
                .filter(user => {
                    return regExp.test(user.email)
                        || regExp.test(user.lastName)
                        || regExp.test(user.firstName)
                        || regExp.test((ROLES[user.role] && ROLES[user.role].label) || user.role)
                })

            this.setState({
                searchTerm,
                users
            })
        }
    }

    toggleSelection (key) {
        let selection = this.state.selection
        key = parseInt(key.split('-')[1]) || 0
        key = isNaN(key) ? 0 : key
        const keyIndex = selection.indexOf(key)

        if (keyIndex >= 0) {
            selection = [ ...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1) ]
        } else {
            selection = [ ...selection, key]
        }

        this.setState({ selection })
    }

    toggleAll (selectAll) {
        selectAll = typeof selectAll === 'boolean'
            ? selectAll
            : this.state.selectAll ? false : true
        let selection = []
        if (selectAll && this.props.users) {
            selection = this.props.users.map(u => u.id)
        }
        this.setState({ selectAll, selection })
    }

    isSelected (key) {
        return this.state.selectAll === true || this.state.selection.includes(key)
    }

    render() {

        const {
            searchTerm='',
            selectAll,
            selection=[],
            users=[]
        } = this.state

        const {
            account = null,
            isLoading = true
        } = this.props

        const {
            filterData,
            isSelected,
            toggleAll,
            toggleSelection,
        } = this

        if (isLoading === false && !Array.isArray(users)) {
            return <PageNotFound />
        }

        const hasSelection = Array.isArray(selection) && selection.length > 0

        return (
            <section id="users" className={ CLN('users') }>

                { account && account.name
                    ? <Title title={ 'Utilisateurs de ' + account.name } />
                    : null }

                <UserHead
                    searchTerm={ searchTerm }
                    selection={ selection }
                    hasSelection={ hasSelection }
                    toggleAll={ toggleAll }
                    filterData={ filterData }
                />

                <div className={ CLN(['users', 'body']) }>
                    <UserTable
                        data={ users }
                        selectAll={ selectAll }
                        isLoading={ isLoading }
                        isSelected={ isSelected }
                        toggleAll={ toggleAll }
                        toggleSelection={ toggleSelection }
                    />
                </div>

            </section>
        )
    }
}

export default connect(
    state => ({
        account: state.account || {},
        token: (state.user && state.user.token) || '',
        users: (state.users && state.users.data) || {},
        teams: (state.teams && state.teams.data) || {},
        isLoading: state.users && state.users.isLoading
    }),
    dispatch => ({
        updateAccount: (account) => dispatch(setAccount(account)),
        getUserData: (company) => dispatch(getUserData(company)),
        getTeamData: (company) => dispatch(getTeamData(company))
    })
)(Users)
