import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import { CloseIcon } from '../Icons'

import { deleteUser } from '../../actions/users'
import { openModal } from '../../actions/ui'

import { classes as CLN } from '../../utils/classNames'

import './UserDelete.sass'

export const DELETE_USER_CONFIRM_MODAL = {
    title: 'Dissocier l\'utilisateur',
    button: 'Dissocier l\'utilisateur',
    hasDismissButton: true,
    id: 'user-delete'
}

export const DeleteUserConfirmModalBody = ({ count=1, fullName='', email='', company='' }) => (
    fullName && email
        ? <p>
            Vous allez dissocier l’utilisateur&nbsp;
            { fullName ? <strong>{ fullName }&nbsp;</strong> : null }
            { email ? ` (${ email }) ` : null }
            de l’entreprise { company }.
            <br /><br />
            Il ne bénéficiera plus des avantages liés à l'entreprise.
        </p>
        : <p>
            Vous allez dissocier { count } utilisateur{ count > 1 ? 's' : '' } de l’entreprise { company }.
            <br /><br />
            Ils ne bénéficieront plus des avantages liés à l'entreprise.
        </p>
)

DeleteUserConfirmModalBody.propTypes = {
    fullName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    count: PropTypes.number
}

export const UserDeleteCmp = (props={}) => {
    const {
        classes=[],
        value={},
        openModal,
        deleteUser,
        render,
        company=''
    } = props

    if (!value || !value.id) { return null }

    const className = Array.isArray(classes) && classes.find(cn => !!cn)
    const ids = Array.isArray(value.id) ? [ ...value.id ] : [ value.id ]

    const config = {
        ...DELETE_USER_CONFIRM_MODAL,
        onConfirm: () => deleteUser && deleteUser(ids)
    }

    let modalBodyProps = {
        count: ids.length,
        company
    }

    if (ids.length <= 1) {
        modalBodyProps.fullName = `${ value.firstName } ${ value.lastName }`.trim()
        modalBodyProps.email = value.email
    } else {
        config.title = `Dissocier ${ modalBodyProps.count } utilisateurs`
        config.button = `Dissocier ${ modalBodyProps.count } utilisateurs`
    }

    config.body = <DeleteUserConfirmModalBody { ...modalBodyProps } />

    const handleClick = () => openModal(config)

    return render && typeof render === 'function'
        ? render({ ids, className, handleClick })
        : null
}

UserDeleteCmp.propTypes = {
    company: PropTypes.string,
    classes: PropTypes.array,
    value: PropTypes.object,
    openModal: PropTypes.func,
    deleteUser: PropTypes.func,
    render: PropTypes.func,
}

const UserDelete = connect(
    state => ({
        company: (state.account && state.account.name) || ''
    }),
    dispatch => ({
        deleteUser: (userId) => dispatch(deleteUser(userId)),
        openModal: (config) => dispatch(openModal(config))
    })
)(UserDeleteCmp)

export default UserDelete

export const UserDeleteSpan = (props={}) => (
    <UserDelete { ...props } render={
        ({ ids=[], className='', handleClick }={}) => (
            <span
                aria-label="Supprimer"
                id={ ids }
                className={ className || CLN('user-delete', 'table-cell') }
                onClick={ handleClick }>
                <CloseIcon />
            </span>
        )
    } />
)

export const UserDeleteButton = (props={}) => (
    <UserDelete { ...props } render={
        ({ ids=[], className='', handleClick }={}) => (
            <button
                type="button"
                aria-label="Supprimer"
                id={ ids }
                className={ className || CLN('user-delete', 'button') }
                onClick={ handleClick }>
                Dissocier
            </button>
        )
    } />
)
