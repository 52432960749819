import React from 'react'

import { DashboardBox } from './Dashboard'
import Lazy from '../Lazy/Lazy'
import ProgressCard from '../Card/ProgressCard'

const GlobalSection = (props={}) => {

    const {
        data = {},
        loading = true
    } = props

    return (
        <Lazy height={ 100 }>
            <div className="row">
                <div className="col-xs-12">
                    <DashboardBox loading={ loading }>
                        <ProgressCard
                            className='dashboard_card dashboard_card--active-user-ratio'
                            label="des salariés inscrits ont déjà commandé FoodChéri"
                            value={ data && data.users.active }
                            max={ data && data.users.count }
                            unit={ 'utilisateurs' }
                            info="active-user-ratio"
                        />
                    </DashboardBox>
                </div>
            </div>
        </Lazy>
    )
}

export default GlobalSection
