import _ from 'lodash'
import { getDashboardData as fetchDashboardData } from '../api/dashboard'
import { signOut } from './security'
import { setAccount } from './account'
import { openModal } from './ui'

import { getErrorMessage } from '../utils/errorMessages'

export const ERROR_MESSAGE_SCOPE = 'dashboard'

export const GET_DASHBOARD_DATA_ACTION = 'get-dashboard-data'
export const SET_DASHBOARD_DATA_ACTION = 'set-dashboard-data'
export const CLEAR_DASHBOARD_DATA_ACTION = 'clear-dashboard-data'

export const getDashboardData = (currentPeriod, company) => {
    return (dispatch, getState) => {
        const {
            user: {
                token=''
            } = {},
            dashboard={}
        } = getState()

        const lastData = dashboard.data || {}

        dispatch({ type: GET_DASHBOARD_DATA_ACTION })

        if (token) {
            return fetchDashboardData(currentPeriod, company, token)
                .then(data => {
                    if (data) {
                        dispatch({
                            type: SET_DASHBOARD_DATA_ACTION,
                            data
                        })
                        if (data.account && !_.isEqual(data.account, lastData.account)) {
                            dispatch(setAccount(data.account))
                        }

                        if (!data.period || !data.period.current || !data.period.current.orders || !data.period.current.orders.count) {
                            dispatch(openModal({
                                title: 'On y est presque !',
                                body: 'Les commandes de vos collaborateurs s\'afficheront ici dès qu\'elles auront été livrées.'
                            }))
                        }
                    } else {
                        dispatch(clearDashboardData())
                    }
                })
                .catch((response={}) => {
                    dispatch(clearDashboardData())
                    switch (response.code) {
                    case 404: // Not found
                    case 403: // Forbidden
                        dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                        break
                    case 401: // Unauthorized
                        dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                        break
                    default:
                        dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
                    }
                })
        }
    }
}

export const clearDashboardData = () => {
    return dispatch => {
        dispatch({ type: CLEAR_DASHBOARD_DATA_ACTION })
    }
}
