import moment from 'moment'
import React from 'react'

import { DashboardBox } from '../Dashboard'

import ChartCard from '../../Card/ChartCard'

import AreaChart from '../../Charts/Area'

import { toCurrencyString } from '../../../utils/stringFormats'

moment.locale('fr')

export const filterOrderDetail = ({ date=null }) => {
    if (!date) return false
    date = moment(date)
    if (!date.isValid()) return false
    if (date.isoWeekday() > 5) return false
    if (moment().isBefore(date)) return false

    return true
}

const OrderDetailRow = (props={}) => {
    const {
        loading=true,
        minHeight='auto'
    } = props

    const current = props.current || {}
    const previous = props.previous || {}

    const orderCount = {
        currentValue: (current.orders && current.orders.count) || 0,
        previousValue: (previous.orders && previous.orders.count) || 0,
        highLightValue: current.orders && toCurrencyString(current.orders.averageBasket)
    }

    let orderDetail = (current.orders && current.orders.detail) || []

    orderDetail = orderDetail
        .filter(filterOrderDetail)

    return (
        <div className="row">
            <div className="col-xs-12 col-lg-12">
                <DashboardBox style={{ minHeight }} loading={ loading }>
                    <ChartCard
                        className="dashboard_card dashboard_card--order-count"
                        info="order-count"
                        currentValue={ orderCount.currentValue }
                        lastValue={ orderCount.previousValue }
                        label="Commandes servies"
                        highLightType="currency"
                        highLightLabel="Panier moyen"
                        highLightValue={ orderCount.highLightValue }>
                        <AreaChart
                            xDataKey="date"
                            data={ orderDetail }
                            className="order-count_card_chart" />
                    </ChartCard>
                </DashboardBox>
            </div>
        </div>
    )
}

export default OrderDetailRow
