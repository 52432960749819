import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import Head from './Head'
import Links from './Links'

import { classes as CLN } from '../../utils/classNames'

import './MainMenu.sass'

export const MainMenu = ({ routes=[], logo='', name='', roles=[] }) => (
    <aside id="main-menu">
        <Head className={ CLN(['main menu','head'])} logo={ logo } name={ name } />
        <Links className={ CLN(['main menu','links'])} routes={ routes } roles={ roles }/>
    </aside>
)

MainMenu.propTypes = {
    route: PropTypes.array,
    logo: PropTypes.string,
    name: PropTypes.string,
    roles: PropTypes.array,
    className: PropTypes.string
}

export default connect((
    state => ({
        logo: (state.account && state.account.logo) || '',
        name: (state.account && state.account.name) || '',
        roles: (state.user && state.user.roles) || undefined
    })
))(MainMenu)
