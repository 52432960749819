import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import SelectInput from '../Inputs/SelectInput'

import { classes as CLN } from '../../utils/classNames'

import { updateUser } from '../../actions/users'

import ROLES from '../../data/roles'

import './TableUpdate.sass'

const roles = Object.values(ROLES)
    .filter(r => r.showOnUserSelect && r.showOnUserSelect === true)

export class TableUpdate extends React.Component {
    state = {
        selectable: false
    }

    toggleSelectable = (isSelectable) => {
        const selectable = typeof isSelectable === 'boolean'
            ? isSelectable
            : !this.state.selectable

        selectable !== this.state.selectable
            && this.setState({ selectable })
    }

    render () {
        const {
            value='',
            account={},
            column: { id='' }={},
            original={},
            updateUser
        } = this.props

        const { selectable = false } = this.state
        const { toggleSelectable } = this
        const userId = original.id

        if (!id || !userId) { return null }

        let items = id === 'role'
            ? roles
            : account[id] || []

        const selected = items.find(({ id }) => value === id)
            || items.find(({ label }) => value === label)
            || items.find(({ isDefault=false }) => isDefault)
            || null

        let label = (selected && selected.label) || String(value) || ''
        let addClassName =  (selected && selected.className) || ''

        return selectable && items && items.length > 0
            ? <SelectInput
                onMouseLeave={ toggleSelectable.bind(this, false) }
                name={ id }
                items={ items }
                selected={ null }
                placeholder={ label }
                onChange={ updateUser && updateUser.bind(this, userId, id) } />
            : <div
                onMouseEnter={ toggleSelectable.bind(this, true) }
                id={ id }
                className={ addClassName + ' ' + CLN(['select-input', 'input'], 'static') }>
                { label }
            </div>
    }
}

TableUpdate.propTypes = {
    classes: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default connect(
    state => ({
        account: {
            team: (state.teams && state.teams.teamListData) || undefined
        }
    }),
    dispatch => ({
        updateUser: (userId, id, value) => dispatch(updateUser(userId, id, value))
    })
)(TableUpdate)
