import React from 'react'

import { DashboardBox } from '../Dashboard'

import PictureCard from '../../Card/PictureCard'

import { sum, ratio } from '../../../utils/statistics'

import nutriscoreImage from '../../../images/nutriscore.svg'
import labelImage from '../../../images/label.svg'
import plateImage from '../../../images/plate.svg'

const NutritionalStatRow = (props={}) => {
    const {
        loading=true,
        minHeight='auto'
    } = props

    const current = props.current || {}

    let nutriscoreA = 0

    if (current.products && current.products.nutriscore) {
        const total = Object.keys(current.products.nutriscore)
            .reduce((total, score) => sum([ total, current.products.nutriscore[score] ]), 0)

        nutriscoreA = ratio(current.products.nutriscore.a, total, true)
    }

    const certifiedProducts = (current.products && current.products.certified) || 0
    const caloriesAverage = Math.round(current.products && current.products.caloriesAverage) || 0

    return (
        <div className="row">
            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }}loading={ loading }>
                    <PictureCard
                        src={ nutriscoreImage }
                        label='Plats en score A'
                        info='nutriscore'
                        currentValue={ nutriscoreA }
                        type="percentage"
                        className='dashboard_card dashboard_card--nutriscore'
                    />
                </DashboardBox>
            </div>

            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }}loading={ loading }>
                    <PictureCard
                        src={ labelImage }
                        label='Des produits sont labelisés'
                        info='certified-products'
                        currentValue={ certifiedProducts }
                        type="percentage"
                        className='dashboard_card dashboard_card--certified-products'
                    />
                </DashboardBox>
            </div>

            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }}loading={ loading }>
                    <PictureCard
                        src={ plateImage }
                        label='En moyenne par plat'
                        info='average-calories'
                        currentValue={caloriesAverage }
                        type="custom"
                        symbol="kcal"
                        className='dashboard_card dashboard_card--average-calories'
                    />
                </DashboardBox>
            </div>
        </div>
    )
}

export default NutritionalStatRow
