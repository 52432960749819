import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'

import { classes as CLN } from '../../utils/classNames'

import './Sticky.sass'

const Sticky = (props) => {
    const {
        scrollLimit=0,
        children = null,
        className=''
    } = props

    const element = useRef(null)
    const [ scroll, setScroll ] = useState(window.scrollY)

    useEffect(() => {
        const updateScroll = () => setScroll(window.scrollY)
        const cleanUp = () => window.removeEventListener('scroll', updateScroll)
        window.addEventListener('scroll', updateScroll)

        return cleanUp
    })

    const top = (element
        && element.current
        && element.current.offsetTop)
        || 0

    const limit = scrollLimit
        && !isNaN(parseInt(scrollLimit))
        ? parseInt(scrollLimit)
        : top

    let classes = CLN(['sticky'], { active: scroll > limit })

    if (className) {
        classes += ` ${ className }`
    }

    return (
        <div
            ref={ element }
            className={ classes }>
            { children }
        </div>
    )
}

Sticky.propTypes = {
    className: PropTypes.string,
    scrollLimit: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default Sticky
