import { combineReducers } from 'redux'

import {
    GET_USER_DATA_ACTION,
    SET_USER_DATA_ACTION,
    CLEAR_USER_DATA_ACTION,
    UPDATE_USER_ACTION,
    USER_UPDATED_ACTION
} from '../actions/users'

export const data = (state = [], action) => {
    let newState = Array.isArray(state) ? [ ...state ] : []

    switch (action.type) {
    case SET_USER_DATA_ACTION:
        newState = action.data.reverse()
        break
    case GET_USER_DATA_ACTION:
    case CLEAR_USER_DATA_ACTION:
        newState = []
        break
    default:
        break
    }

    return newState
}

export const isLoading = (state = false, action) => {
    let newState = state

    switch (action.type) {
    case GET_USER_DATA_ACTION:
    case UPDATE_USER_ACTION:
        newState = true
        break
    case SET_USER_DATA_ACTION:
    case CLEAR_USER_DATA_ACTION:
    case USER_UPDATED_ACTION:
        newState = false
        break
    default:
        break
    }

    return newState
}

export default combineReducers({
    data,
    isLoading
})
