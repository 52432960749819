import { connect } from 'react-redux'
import { Component } from 'react'

import { signOut } from '../../actions/security'

export class SignOut extends Component {
    componentWillMount () {
        this.props.signOut && this.props.signOut('Vous êtes bien déconnecté !')
    }
    render () {
        return this.props.children || null
    }
}

const emptyProps = {}

export default connect(
    () => emptyProps,
    dispatch => ({
        signOut: (message) => dispatch(signOut(message))
    })
)(SignOut)
