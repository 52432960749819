import PropTypes from 'prop-types'
import React, { useState } from 'react'

import useTimeout from '../../hooks/useTimeout'

export const INCREMENTS = [1, 3, 27, 99]

export const incrementNumber = (number, targetNumber) => {
    let increment = String(parseInt(number, 10)).length
    increment = Math.min(INCREMENTS.length - 1, increment)
    increment = Math.max(1, increment)
    increment = INCREMENTS[increment]

    return Math.min(number + increment, targetNumber)
}

export const GrowingNumber = ({ target='' }) => {
    const targetNumber = parseFloat(target)
    const isNotNumber = isNaN(targetNumber)

    const [ number, updateNumber ] = useState(isNotNumber ? target : 0)

    const grow = (clearTimeout) => {

        if (isNotNumber || targetNumber <= number) {
            updateNumber(target)

            return clearTimeout()
        }

        return updateNumber(incrementNumber(number, targetNumber))
    }

    useTimeout(grow, 40)

    return number
}

GrowingNumber.propTypes = {
    target: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
}

export default React.memo(GrowingNumber)
