import React, { Component } from 'react'

import { classes as CLN } from '../../utils/classNames'
import { SearchIcon, CloseIcon } from '../Icons'

import './TeamHead.sass'

class TeamHead extends Component {

    state = {
        searchState: {
            focused: false,
            empty: true
        }
    }

    handleSearchChange = (e) => {
        this.setState({
            searchState: {
                ...this.state.searchState,
                empty: !!(e.target && e.target.value)
            }
        })

        this.props.filterData && this.props.filterData(e)
    }

    handleSearchFocus = () => {
        this.setState({
            searchState: {
                ...this.state.searchState,
                focused: true
            }
        })
    }

    handleSearchBlur = () => {
        this.setState({
            searchState: {
                ...this.state.searchState,
                focused: false
            }
        })
    }

    render() {
        const {
            searchTerm,
            openModal
        } = this.props

        const {
            searchState={
                focused: false,
                empty: true
            }
        } = this.state

        const {
            handleSearchBlur,
            handleSearchFocus,
            handleSearchChange
        } = this

        return (
            <div className={ CLN(['team', 'head']) }>
                <h2 className={ CLN(['team', 'head', 'title']) }>Équipes</h2>

                <div className={ CLN(['team', 'head', 'controls']) }>
                    <button
                        type="button"
                        className={ CLN(['team', 'head', 'controls', 'team-create']) }
                        onClick={ openModal }>
                        Ajouter une équipe
                    </button>
                </div>

                <label htmlFor="teamSearch" className={ CLN(['team', 'head', 'search'], { empty: searchState.empty && !searchState.focused }) }>
                    <SearchIcon
                        className={ CLN(['team', 'head', 'search', 'icon']) }
                    />

                    <input
                        className={ CLN(['team', 'head', 'search', 'field']) }
                        type="text"
                        placeholder="Rechercher une équipe, un manager, etc..."
                        name="teamSearch"
                        onChange={ handleSearchChange }
                        onFocus={ handleSearchFocus }
                        onBlur={ handleSearchBlur }
                        value={ searchTerm }
                    />

                    <span
                        className={ CLN(['team', 'head', 'search', 'close']) }
                        onClick={ handleSearchChange }>
                        <CloseIcon />
                    </span>
                </label>
            </div>
        )
    }

}

export default TeamHead