import React, { Fragment, Component } from 'react'

import { StarIcon2, UserIcon2 } from '../Icons'

import {classes as CLN} from '../../utils/classNames'

import './TeamList.sass'

export const TeamListItem = (props={}) => {
    const {
        openModal,
        team_type={},
        name='',
        managers=[],
        count_customers=0
    } = props

    return (
        <div onClick={ openModal } className={ CLN(['teams', 'body', 'list', 'row'])}>
            <div className={ CLN(['teams', 'body', 'list', 'item', 'name'])}>
                <span className={ CLN(['teams', 'body', 'list', 'item', 'name', 'team'])}>{ name ||  team_type.label}</span>

                { managers.length > 0
                    ?
                    <span className={CLN(['teams', 'body', 'list', 'item', 'name', 'managers'])}>

                        <StarIcon2 className={CLN(['teams', 'body', 'list', 'item', 'name', 'icon'])} />
                        { managers.map((manager) => {
                            return manager.first_name + ' ' + manager.last_name
                        }).join(', ')}
                    </span>
                    : null
                }

            </div>
            <div className={ CLN(['teams', 'body', 'list', 'item', 'total-users'])}>
                <span className={ CLN(['teams', 'body', 'list', 'item', 'total-users', 'number'])}>{ count_customers}</span>
                <UserIcon2 className={ CLN(['teams', 'body', 'list', 'item', 'total-users', 'icon'])}/>
            </div>
        </div>
    )
}

export class TeamList extends Component{

    render() {
        const {
            classNameWrapper,
            data=[]
        } = this.props

        return(
            <div className={ CLN(['teams', 'body', 'list'], classNameWrapper) }>
                {
                    Array.isArray(data) && data.length > 0
                        ? data.map(item => (
                            <TeamListItem
                                { ...item }
                                key={ `teams-body-row_${ item.id }` }
                            />
                        ))
                        : <Fragment>
                            <p>
                                Votre entreprise ne possède pas encore d’équipe.
                            </p>
                            <br/>
                            <p>
                                En assignant vos utilisateurs dans des équipes, vous permettez à leurs managers de gérer leurs droits et paramètres et d’avoir une vue Dashboard dédiée à l’équipe.
                            </p>
                        </Fragment>
                }
            </div>
        )
    }
}

export default TeamList
