import jwt from 'jsonwebtoken'
import qs from 'querystring'

import URL from './urls'

const fetchParams = {
    method: 'GET',
    mode: 'cors',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*'
    },
}

export const getDashboardData = (period, company, token='') => {
    const query = qs.stringify({ ...period })

    const tokenData = jwt.decode(token) || {}
    company = company || tokenData.id_company || ''

    const url =  URL.getDashboardData.replace(':company', company) + '?' + query

    const params = {
        ...fetchParams,
        headers: {
            ...fetchParams.headers,
            'Authorization': 'Bearer ' + token
        }
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
        .then(json => {
            if (!json || typeof json !== 'object') { return {} }

            if (json.code && json.code !== 200) {
                return Promise.reject(json)
            }

            const data = Array.isArray(json) && json.length
                ? json[0].data
                : json.data

            return data || {}
        })

}
