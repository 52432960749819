import moment from 'moment'
import React from 'react'

import { DashboardBox } from '../Dashboard'

import PictureCard from '../../Card/PictureCard'

import { ratio } from '../../../utils/statistics'

import userActiveImage from '../../../images/user-active.svg'
import userNewImage from '../../../images/user-new.svg'
import basketImage from '../../../images/basket.svg'

moment.locale('fr')

const UserRow = (props={}) => {

    const {
        loading=true,
        minHeight='auto'
    } = props

    const current = props.current || {}
    const previous = props.previous || {}

    const activeUserCount = {
        currentValue: (current.users && current.users.active) || 0,
        previousValue: (previous.users && previous.users.active) || 0,
    }

    const newUserCount = {
        currentValue: (current.users && current.users.new) || 0,
        previousValue: (previous.users && previous.users.new) || 0,
    }

    const recurrence = {
        currentValue: current.users && current.orders && ratio(current.orders.count, current.users.active),
        previousValue: previous.users && previous.orders && ratio(previous.orders.count, previous.users.active)
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }}  loading={ loading }>
                    <PictureCard
                        src={ userActiveImage }
                        label='Utilisateurs actifs'
                        info='active-user-count'
                        currentValue={ activeUserCount.currentValue }
                        lastValue={ activeUserCount.previousValue }
                        className='dashboard_card dashboard_card--active-user-count'
                    />
                </DashboardBox>
            </div>

            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }} loading={ loading }>
                    <PictureCard
                        src={ userNewImage }
                        label='Nouveaux utilisateurs'
                        info='new-user-count'
                        currentValue={ newUserCount.currentValue }
                        lastValue={ newUserCount.previousValue }
                        className='dashboard_card dashboard_card--new-user-count'
                    />
                </DashboardBox>
            </div>
            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }} loading={ loading }>
                    <PictureCard
                        src={ basketImage }
                        label='Commandes / utilisateur actif'
                        info='recurrence'
                        currentValue={ recurrence.currentValue }
                        lastValue={ recurrence.previousValue }
                        className='dashboard_card dashboard_card--recurrence'
                    />
                </DashboardBox>
            </div>
        </div>
    )
}

export default UserRow
