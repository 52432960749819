import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { classes as CLN } from '../../utils/classNames'
import { Loader } from '../Users/TableLoader'

import './MultipleSelect.sass'

export const MultipleSelectList = (props={}) => {
    const {
        items=[],
        selectItems,
        indexPreSelect
    } = props

    if (Array.isArray(items) && items.length === 0) return null

    return(
        <div className={ CLN(['multiple select input', 'suggestion', 'list']) }>
            {items.map((item, i) => (
                <div
                    className={ CLN(['multiple select input', 'suggestion', 'item'], parseInt(indexPreSelect) === i ? 'pre-select' : '') }
                    onClick={ () => selectItems(item) }
                    key={`multiple-select-input_list_${item.id}_${item.first_name}`}>
                    <span className={ CLN(['multiple select input', 'suggestion', 'name']) }>
                        {item.first_name + ' ' + item.last_name}
                    </span>
                    <span className={ CLN(['multiple select input', 'suggestion', 'email']) }>
                        {item.email}
                    </span>
                </div>
            ))}
        </div>
    )
}

export class MultipleSelectInputItem extends Component{
    componentDidMount() {
        if(this.props.isFocus){
            this.multipleSelectInput.focus()
        }
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.isFocus && this.props.isFocus){
            this.multipleSelectInput.focus()
        }
    }

    render() {
        const {
            placeholder,
            handleChange,
            itemsSelected,
            search,
            removeSelected,
            name,
            triggerClick,
            isLoading,
            onFocus,
            onBlur
        } = this.props

        return(
            <div className={ CLN(['multiple select input'], { 'empty': itemsSelected <= 0 }) }>
                {
                    isLoading
                        ? <Loader/>
                        : null
                }
                {
                    itemsSelected.length > 0
                        ?
                        itemsSelected.map((item, index) => (
                            <span
                                className={ CLN(['multiple select input', 'selected list', 'item'])}
                                key={`superkey-${index}`}>
                                <span className={ CLN(['multiple select input', 'selected-list', 'item', 'name'])}>
                                    {item.first_name} {item.last_name}
                                </span>
                                <span
                                    className={ CLN(['multiple select input', 'selected-list', 'remove'])}
                                    onClick={ () => removeSelected(item) }>
                                </span>
                            </span>
                        ))
                        : null
                }

                <input
                    className={ CLN(['multiple select input', 'input'])}
                    id={`multi-select-input_${ CLN(name) }`}
                    ref={(input) => { this.multipleSelectInput = input }}
                    onClick={ triggerClick } onChange={ handleChange }
                    type='text'
                    placeholder={ placeholder }
                    onFocus={ onFocus }
                    onBlur={ onBlur }
                    value={ search }
                    autoComplete="off"/>
            </div>
        )
    }
}

export class MultipleSelect extends Component{
    constructor (props) {
        super(props)

        this.state = {
            selected: this.props.values || [],
            search: '',
            suggestionOpen: false,
            isFocus: false,
            indexPreSelect: -1
        }

        this.selectItem = this.selectItem.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleWindowClick = this.handleWindowClick.bind(this)
        this.removeSelected = this.removeSelected.bind(this)
        this.triggerClick = this.triggerClick.bind(this)
        this.handleKeyDown= this.handleKeyDown.bind(this)
        this.inputOnFocus = this.inputOnFocus.bind(this)
        this.inputOnBlur = this.inputOnBlur.bind(this)
    }

    static propTypes = {
        items: PropTypes.array,
        updateItems: PropTypes.array,
        name: PropTypes.string,
        placeholder: PropTypes.string
    }

    triggerClick() {
        if (this.state.suggestionOpen === false && this.props.suggestionItems.length > 0){
            this.setState({suggestionOpen: true})
        }
    }

    selectItem(select) {
        if (!select) {return}

        const updateSelected = [ ...this.state.selected ]
        const idUpdateSelected = updateSelected.map(({ id }) => id)
        updateSelected.push(select)

        if(idUpdateSelected.indexOf(select.id) !== -1) {
            this.setState({search: '', suggestionOpen: false})
            return
        }

        this.setState({selected: updateSelected, search: '', suggestionOpen: false, isFocus: true})

        this.props.onChange && this.props.onChange(updateSelected.map( id => id ))
    }

    handleWindowClick(e) {
        if (this.state.suggestionOpen === true
            && e.target
            && e.target.id !== `multi-select-input_${ CLN(this.props.name) }`) {
            e.preventDefault()
            e.stopPropagation()

            this.setState({suggestionOpen: false})
        }
    }

    componentDidUpdate(lastProps, lastState) {
        if (!lastState.suggestionOpen && this.state.suggestionOpen) {
            window.addEventListener
            && window.addEventListener('click', this.handleWindowClick)
        } else if (lastState.suggestionOpen && !this.state.suggestionOpen) {
            window.removeEventListener
            && window.removeEventListener('click', this.handleWindowClick)
        }
    }

    handleChange(e) {
        this.setState({search: e.target.value, indexPreSelect: -1})
        this.props.refreshSuggest(e.target.value)
        this.setState({suggestionOpen: e.target.value.length >= 3})
    }

    removeSelected(item) {
        const updateSelected = this.state.selected
        updateSelected.splice(updateSelected.indexOf(item), 1)

        this.setState({selected: [ ...updateSelected] })
        this.props.onChange && this.props.onChange(updateSelected.map( id => id ))
    }

    componentWillUnmount () {
        window.removeEventListener
        && window.removeEventListener('click', this.handleWindowClick)
    }

    handleKeyDown(e) {
        let focused = typeof this.state.indexPreSelect === 'number'
            ? this.state.indexPreSelect
            : -1

        switch (e.keyCode) {
        case 8:
            if (this.state.selected.length > 0 && this.state.search === '') {
                this.removeSelected([...this.state.selected].pop())
            }
            break
        case 13:
            e.preventDefault()
            if (this.props.suggestionItems.length > 0 && this.state.suggestionOpen) {
                this.selectItem(this.props.suggestionItems[focused])
            }
            break
        case 38:
            if (this.props.suggestionItems.length > 0 && this.state.suggestionOpen) {
                e.preventDefault()
                focused -= 1

                focused = focused < 0
                    ? 0
                    : focused
            }
            break
        case 40:
            if (this.props.suggestionItems.length > 0 && this.state.suggestionOpen) {
                e.preventDefault()
                focused += 1

                focused = focused >= this.props.suggestionItems.length
                    ? this.props.suggestionItems.length
                    : focused
            }
            break
        default:
            break
        }

        focused !== this.state.indexPreSelect
        && this.setState({indexPreSelect: focused})
    }

    inputOnFocus() {
        this.setState({isFocus: true})
    }

    inputOnBlur() {
        this.setState({isFocus: false})
    }

    render() {
        const {
            listOpen = false,
            search,
            selected,
            suggestionOpen,
            isFocus,
            indexPreSelect
        } = this.state

        const {
            name,
            placeholder,
            suggestionItems = [],
            autoCompleteIsLoading,
        } = this.props

        const {
            handleChange,
            removeSelected,
            triggerClick,
            handleKeyDown,
            inputOnFocus,
            inputOnBlur,
            selectItem
        } = this

        return(
            <div onKeyDown={ handleKeyDown } className={ CLN(['multiple select input container']) }>

                <MultipleSelectInputItem
                    handleChange={ handleChange }
                    itemsSelected={ selected }
                    placeholder={ placeholder }
                    listOpen={ listOpen }
                    search={ search }
                    removeSelected={ removeSelected }
                    name={ name }
                    triggerClick={ triggerClick }
                    isLoading={ autoCompleteIsLoading }
                    isFocus={ isFocus }
                    onFocus={ inputOnFocus }
                    onBlur={ inputOnBlur }
                />

                { search.length > 0 && suggestionOpen === true
                    ? <MultipleSelectList
                        items={ suggestionItems }
                        selectItems={ selectItem }
                        indexPreSelect={ indexPreSelect }
                    />
                    : null
                }

            </div>
        )
    }
}

export default MultipleSelect