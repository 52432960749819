import {
    AUTHORIZE_ACTION,
    SIGN_IN_ACTION,
    SIGN_OUT_ACTION
} from '../actions/security'

export const signIn = (state = '', action) => {
    let newState

    switch (action.type) {
    case AUTHORIZE_ACTION:
    case SIGN_IN_ACTION:
        newState = ''
        break
    case SIGN_OUT_ACTION:
        newState = action.message || ''
        break
    default:
        newState = state
    }


    return newState
}
