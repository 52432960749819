import { SET_ACCOUNT_ACTION } from '../actions/account'

export const account = (state = {}, action) => {
    let newState = state

    if (action.type === SET_ACCOUNT_ACTION) {
        newState = action.account
    }

    return newState
}
