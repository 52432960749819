import PropTypes from 'prop-types'
import React from 'react'

import Value from './Value'

import { classes as CLN } from '../../utils/classNames'

export const CardHighlight = (props = {}) => {
    const {
        label='',
        value='',
        type='raw',
        className='chart-card_head_highlight'
    } = props

    if (!value || !label) {
        return null
    }

    return (
        <div className={ CLN(className) }>
            <div className={ CLN([ className, 'label' ])}>{ label }</div>
            <div className={ CLN([ className, 'value' ])}>
                <Value
                    baseClass={ CLN([ className, 'value' ])}
                    value={ value }
                    type={ type } />
            </div>
        </div>
    )
}

CardHighlight.propTypes = {
    label: PropTypes.string,
    value:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    type: PropTypes.string,
    className: PropTypes.string
}

export default React.memo(CardHighlight)
