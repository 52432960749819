import PropTypes from 'prop-types'
import React from 'react'

export const StarIcon = (props = {}) => {
    if (!props.starred) {
        return null
    }

    if (!props.height || props.height <= props.maxBarSize) {
        return null
    }

    const x = props.x && !isNaN(parseFloat(props.x))
        ? parseFloat(props.x)
        : 0
    const y = props.y && !isNaN(parseFloat(props.y))
        ? parseFloat(props.y)
        : 0

    const width = props.width && !isNaN(parseFloat(props.width))
        ? parseFloat(props.width)
        : props.maxBarSize

    const starWidth = Math.round(width / 2)
    const marginLeft = Math.round((width - starWidth) / 2)

    return (
        <svg
            className="bar-chart_star-icon"
            x={ x + marginLeft }
            y={ y + 10 }
            width={ starWidth }
            height={ starWidth }
            viewBox="0 0 21 20"
            fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4203 0.378718C11.3044 0.142842 11.0702 -0.00394331 10.8161 8.06752e-05C10.562 0.00410466 10.3322 0.15824 10.2232 0.397678L7.57858 6.20773L1.49078 7.22912C1.2399 7.27121 1.03368 7.45822 0.958835 7.71152C0.883989 7.96481 0.953497 8.24047 1.13813 8.42258L5.61839 12.8415L4.66844 19.1964C4.62929 19.4583 4.73607 19.7206 4.9439 19.8732C5.15172 20.0257 5.42453 20.0419 5.64763 19.915L11.0612 16.836L16.5619 19.7422C16.7886 19.8619 17.0608 19.8371 17.2641 19.678C17.4674 19.519 17.5665 19.2534 17.5197 18.9928L16.3852 12.671L20.7348 8.11221C20.914 7.92433 20.9755 7.64661 20.8933 7.3958C20.8111 7.14499 20.5996 6.96459 20.3476 6.93046L14.2328 6.10234L11.4203 0.378718Z"
                fill="white"/>
        </svg>
    )
}
StarIcon.propTypes = {
    starred: PropTypes.bool,
    height: PropTypes.number,
    x: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    y: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
}

export default React.memo(StarIcon)
