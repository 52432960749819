import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import SelectInput from '../Inputs/SelectInput'
import { UserDeleteButton } from './UserDelete'

import { updateUser } from '../../actions/users'

import ROLES from '../../data/roles'

import './UserUpdate.sass'

export const UserUpdate = (props={}) => {
    const {
        account,
        selection=[],
        updateUser,
        teams
    } = props

    if (!account) { return null }

    // const {
    //     teams=[],
    //     companyDiscountTypes=[]
    // } = account

    return (
        <Fragment>
            {/*
            <SelectInput
                name="companyDiscountType"
                items={ companyDiscountTypes }
                selected={ null }
                placeholder="Subvention"
                onChange={ updateUser.bind(this, selection, 'companyDiscountType') }/>
            */}
            <SelectInput
                name="team"
                items={ teams }
                updateSelected={ false }
                selected={ null }
                placeholder="Équipe"
                onChange={ updateUser && updateUser.bind(this, selection, 'team') }/>
            <SelectInput
                name="role"
                items={ Object.values(ROLES).filter(r => r.showOnUserSelect === true) }
                updateSelected={ false }
                selected={ null }
                placeholder="Rôle"
                onChange={ updateUser && updateUser.bind(this, selection, 'role') }/>

            <UserDeleteButton
                value={{ id: selection }}
            />
        </Fragment>
    )
}

UserUpdate.propTypes = {
    updateUser: PropTypes.func,
    account: PropTypes.object,
    selection: PropTypes.array
}

export default connect(
    state => ({
        account: state.account || {},
        teams: (state.teams && state.teams.teamListData) || {}
    }),
    dispatch => ({
        updateUser: (userId, id, value) => dispatch(updateUser(userId, id, value))
    })
)(UserUpdate)
