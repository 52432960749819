import React from 'react'

import Link from '../MainMenu/Link'

import './PageNotFound.sass'

export const PageNotFound = () => (
    <section className="not-found-section">
        <svg width="143" height="29" viewBox="0 0 143 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.48577 2.13201H0.509766V4.41001H9.48577V2.13201Z" fill="#000000"/>
            <path d="M13.3939 0.568007H10.3339L19.0039 28.278H22.0639L13.3939 0.568007Z" fill="#000000"/>
            <path d="M44.2051 26H23.9751V28.754H44.2051V26Z" fill="#000000"/>
            <path d="M51.1945 1.24801C48.4405 5.32801 47.0805 9.95201 47.0805 15.12C47.0805 20.254 48.4405 24.878 51.1945 28.992L53.7445 28.04C51.2625 24.096 50.0045 19.778 50.0045 15.12C50.0045 10.428 51.2625 6.11001 53.7445 2.16601L51.1945 1.24801Z" fill="#000000"/>
            <path d="M69.8905 0.432007L67.2725 1.31601C68.1225 3.08401 70.0605 8.35401 70.6045 10.292L73.2565 9.37401C72.6785 7.47001 70.6385 2.06401 69.8905 0.432007ZM81.8585 1.72401C81.2125 6.58601 79.2065 12.162 76.4185 15.698C72.9845 20.05 67.9185 23.314 63.0565 24.742L65.4025 27.122C70.1965 25.422 75.2285 21.92 78.7305 17.296C81.5185 13.624 83.3545 8.76201 84.3745 4.54601C84.5105 4.00201 84.7485 3.18601 84.9865 2.60801L81.8585 1.72401ZM60.4045 2.47201L57.7185 3.45801C58.5345 4.92001 60.8805 10.666 61.5265 12.774L64.2125 11.822C63.4305 9.57801 61.2885 4.37601 60.4045 2.47201Z" fill="#000000"/>
            <path d="M89.0665 2.16601C91.5485 6.11001 92.8065 10.428 92.8065 15.12C92.8065 19.778 91.5485 24.096 89.0665 28.04L91.6165 28.992C94.3705 24.878 95.7305 20.254 95.7305 15.12C95.7305 9.95201 94.3705 5.32801 91.6165 1.24801L89.0665 2.16601Z" fill="#000000"/>
            <path d="M118.812 26H98.5825V28.754H118.812V26Z" fill="#000000"/>
            <path d="M132.398 0.568007H129.372L120.668 28.278H123.762L132.398 0.568007Z" fill="#000000"/>
            <path d="M142.265 2.13201H133.289V4.41001H142.265V2.13201Z" fill="#000000"/>
        </svg>
        <h2>Cette page n’existe plus ou n’a jamais existé.</h2>
        <Link id="not-found-section_link" className="not-found-section_link" label="Retourner au site" />
    </section>
)
export default PageNotFound
