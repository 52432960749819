import PropTypes from 'prop-types'
import React from 'react'

import { classes as CLN } from '../../utils/classNames'

class TableCheckbox extends React.PureComponent {

    handleClick = e => {
        const {
            id,
            onClick,
            row
        } = this.props

        e.stopPropagation()
        onClick && onClick(id, e.shiftKey, row)
    }

    render () {
        const {
            checked=false,
            id,
            className='table-checkbox',
        } = this.props

        return (
            <span
                aria-label={ `${ checked ? 'Déséléctionner' : 'Séléctionner' }` }
                id={ id }
                className={ CLN(className, { checked }) }
                onClick={ this.handleClick } />
        )
    }
}

TableCheckbox.propTypes = {
    checked: PropTypes.bool,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.string,
    onClick: PropTypes.func,
    row: PropTypes.object
}

export default TableCheckbox
