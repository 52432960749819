import 'moment/locale/fr'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import {classes as CLN} from '../../utils/classNames'
import TeamHead from './TeamHead'
import TeamList from './TeamList'
import {CreateTeamForm, UpdateTeamForm} from './TeamForm.js'

import { getTeamData, getTeamTypes } from '../../actions/teams'
import { openModal, closeModal } from '../../actions/ui'

import Title from '../App/Title'

import './Teams.sass'
import {obfuscate} from '../../utils/stringFormats'

import _ from 'lodash'
import Lazy from '../Lazy/Lazy'

export const LoadingBox = (props = {}) => {
    const {
        children = null,
        loading = true
    } = props

    const style = loading && props.style ? props.style : undefined

    return (
        <div style={ style } className={ CLN('box', { loading }) }>
            { !loading
                ? <Lazy offset={ 0 }>{ children }</Lazy>
                : null }
        </div>
    )
}

export const TEAM_CREATE_FORM_MODAL_CONFIG = {
    title: 'Nouvelle Équipe',
    hasConfirmButton: false,
    id: 'team-form',
    button: 'créer l\'équipe'
}

export const TEAM_UPDATE_FORM_MODAL_CONFIG = {
    title: 'Modifier l\'équipe',
    hasConfirmButton: false,
    id: 'team-form',
    button: 'enregistrer'
}



export class Teams extends Component {

    constructor (props) {
        super(props)

        this.state = {
            searchTerm: '',
            filteredTeams: (this.props.teams && Object.values(this.props.teams)) || []
        }
    }

    getTeamData = () => {
        let companyId = (this.props.match
            && this.props.match.params
            && this.props.match.params.companyId)
            || ''

        companyId = obfuscate.parse(companyId) || ''

        this.props.getTeamData(companyId)
        this.props.getTeamTypes()
    }

    filterData = (regExp, item={}) => {
        const {
            name='',
            team_type={},
            managers=[]
        } = item

        if (!regExp)
            return false

        if (name && regExp.test(name))
            return true

        if (team_type.label && regExp.test(team_type.label))
            return true

        if (Array.isArray(managers) && managers.some(({ first_name='', last_name='' }) => regExp.test(first_name + ' ' + last_name)))
            return true

        return false
    }

    handleSearch = ({ target }={}) => {
        const searchTerm = (target && target.value) || ''
        let filteredData = (this.props.teams
            && Object.values(this.props.teams))
            || []

        if (searchTerm) {
            const regExp = new RegExp(searchTerm, 'i')
            filteredData = filteredData
                .filter(this.filterData.bind(this, regExp))
        }

        this.setState({
            searchTerm: searchTerm,
            filteredTeams: filteredData
        })
    }

    componentDidMount() {
        this.props.token && this.getTeamData()
    }

    componentDidUpdate(lastProps) {
        if (!_.isEqual(this.props.teams, lastProps.teams)) {
            this.setState({
                filteredTeams: Object.values(this.props.teams)
            })
        }

        if (!_.isEqual(this.props.match, lastProps.match)) {
            return this.getTeamData()
        }

        if (this.props.token !== lastProps.token) {
            return this.getTeamData()
        }
    }

    render() {
        const {
            searchTerm = ''
        } = this.state

        const {
            openModal,
            teamTypes,
            teamFilterCustomers,
            isLoading
        } = this.props

        const {
            handleSearch
        } = this

        const configTeamCreate = {
            ...TEAM_CREATE_FORM_MODAL_CONFIG,
            body: <CreateTeamForm teamTypes={ teamTypes } filterCustomers={ teamFilterCustomers } />
        }

        const LoadingBoxStyle = {
            minHeight:'93px',
            marginBottom:'20px'
        }

        const filteredTeams = this.state.filteredTeams.map(team => ({
            ...team,
            openModal: () => openModal({
                ...TEAM_UPDATE_FORM_MODAL_CONFIG,
                body: <UpdateTeamForm teamTypes={ teamTypes } filterCustomers={ teamFilterCustomers } teamData={ team } />
            })
        }))

        return (
            <section id="teams" className={ CLN('teams') }>

                <Title title={'Equipes'} />

                <TeamHead
                    filterData={ handleSearch }
                    searchTerm={ searchTerm }
                    teamTypesData={ teamTypes }
                    openModal={ () => openModal(configTeamCreate) }
                />

                <div className={ CLN(['teams', 'body']) }>
                    {
                        !isLoading ?
                            <TeamList
                                data={ filteredTeams }
                            />
                            :
                            <div className={ CLN(['teams', 'body', 'list']) }>
                                <LoadingBox style={LoadingBoxStyle} loading={ true } />
                                <LoadingBox style={LoadingBoxStyle} loading={ true } />
                                <LoadingBox style={LoadingBoxStyle} loading={ true } />
                            </div>
                    }

                    <div className={ CLN(['teams', 'help']) }>
                        <span>Construisez vos équipes</span>
                        <p>Créez vos équipes, assignez-leur un ou plusieurs managers, puis ajoutez des membres à cette équipe depuis la page Utilisateurs.</p>
                        <span>Que peut faire un manager?</span>
                        <p>Le manager d’une équipe peut voir l’activité et l’historique de l’entreprise. Il peut éditer les utilisateurs, les subventions et les paramètres.</p>
                    </div>
                </div>

            </section>
        )
    }

}

export default connect(
    state => ({
        account: state.account || {},
        token: (state.user && state.user.token) || '',
        teams: (state.teams && state.teams.data) || [],
        teamTypes: (state.teams && state.teams.teamTypes) || [],
        isLoading: state.teams && state.teams.isLoading
    }),
    dispatch => ({
        getTeamData: (company) => dispatch(getTeamData(company)),
        getTeamTypes: () => dispatch(getTeamTypes()),
        openModal: config => dispatch(openModal(config)),
        closeModal: () => dispatch(closeModal())
    })
)(Teams)
