
export const BREAKPOINT_MOBILE_S = 520
export const BREAKPOINT_MOBILE = 768
export const BREAKPOINT_TABLET = 1024
export const BREAKPOINT_DESKTOP = 1170
export const BREAKPOINT_DESKTOP_XL = 1200

let cache = {
    screenWidth: null
}

const isIOS = () => {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
}

const isAndroid = () => {
    return navigator.userAgent.toLowerCase().indexOf('android') !== -1
}

const isMobile = (noCache = false) => {
    return getScreenWidth(noCache) < BREAKPOINT_MOBILE
}

const isTablet = (noCache = false) => {
    return getScreenWidth(noCache) < BREAKPOINT_TABLET && getScreenWidth(noCache) >= BREAKPOINT_MOBILE
}

const isDesktop = (noCache = false) => {
    return getScreenWidth(noCache) >= BREAKPOINT_DESKTOP
}

const getScreenWidth = (noCache = false) => {
    return !cache.screenWidth || noCache ? cache.screenWidth = window.innerWidth : cache.screenWidth
}


export default {
    isIOS,
    isAndroid,
    isMobile,
    isTablet,
    isDesktop,
    getScreenWidth
}