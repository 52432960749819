import PropTypes from 'prop-types'
import React from 'react'

import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    Bar,
    XAxis,
    YAxis,
    Tooltip
} from 'recharts'

import NoChart from './NoChart'
import BarShape from './BarShape'
import { RawValueTooltip, PercentageValueTooltip } from './Tooltip'

import './Bars.sass'

export const BAR_MAX_WIDTH = 42

const Bars = (props = {}) => {
    const {
        className='',
        data=[],
        xDataKey='name',
        unit='',
        hasTooltip=true,
        valueType=''
    } = props

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <NoChart className={ className } />
    }

    let maxValue = 0

    data.map(({ value = 0 }) => {
        maxValue = Math.max(maxValue, value)

        return maxValue
    })

    let tooltipProps = {
        cursor: {
            fill: '#FDFDFD',
            opacity: .1
        }
    }
    if (hasTooltip) {
        switch (valueType) {
        case 'percentage':
            tooltipProps.content = PercentageValueTooltip
            break
        default:
            tooltipProps.content = RawValueTooltip
        }
    }

    return (
        <ResponsiveContainer
            className={ className + ' bar-chart' }
            width="100%"
            minHeight={ 200 }>
            <BarChart
                data={ data }
                margin={{ top: 20, right: 40, bottom: 20, left: 0 }}>

                <CartesianGrid
                    horizontal={ true }
                    vertical={ false }
                    strokeWidth={ 1 }
                    stroke="#F0F0F5"
                />
                <XAxis
                    dataKey={ xDataKey }
                    axisLine={ false }
                    tickLine={ false }
                    padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    domain={['auto', 'auto']}
                />
                <YAxis
                    unit={ unit }
                    interval="preserveStartEnd"
                    tickMargin={ 10 }
                    axisLine={ false }
                    tickLine={ false }
                    padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
                />
                <Bar
                    type='natural'
                    dataKey='value'
                    shape={ <BarShape maxValue={ maxValue } maxBarSize={ BAR_MAX_WIDTH } /> }
                    fill='#64B8E8'
                    maxBarSize={ BAR_MAX_WIDTH }>

                </Bar>
                {
                    hasTooltip
                        ? <Tooltip { ...tooltipProps } />
                        : null
                }
            </BarChart>
        </ResponsiveContainer>
    )
}
Bars.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
    xDataKey: PropTypes.string,
    unit: PropTypes.string,
    valueType: PropTypes.string,
    hasTooltip: PropTypes.bool
}

export default Bars
