import * as API from '../api/teams'

import { signOut } from './security'

import { getErrorMessage } from '../utils/errorMessages'

export const ERROR_MESSAGE_SCOPE = 'teams'

export const GET_TEAM_DATA_ACTION = 'get-team-data'
export const SET_TEAM_DATA_ACTION = 'set-team-data'

export const GET_FILTER_CUSTOMERS_TEAM_DATA_ACTION = 'get-filter-customers-team-data-action'

export const GET_TEAM_TYPES = 'get-team-types'

export const CREATE_TEAM_ACTION = 'create-team'
export const TEAM_CREATED_ACTION = 'team-created'

export const UPDATE_TEAM_ACTION = 'update-team'
export const TEAM_UPDATED_ACTION = 'team-updated'

export const DELETE_TEAM_ACTION = 'delete-team'
export const TEAM_DELETED_ACTION = 'team-deleted'

export const CLEAR_TEAM_DATA_ACTION = 'clear-team-data'
export const CLEAR_MANAGER_TEAM_ACTION = 'clear-manager-team'

export const FORM_TEAM_LOADING = 'form-team-loading'

export const getTeamData = (company) => (dispatch, getState) => {

    const {
        user: {
            token = ''
        } = {}
    } = getState()

    if (!token) { return }

    dispatch({ type: GET_TEAM_DATA_ACTION })

    return API.getTeamData(company, token)
        .then(data => {
            if (data && Array.isArray(data)) {
                dispatch({ type: SET_TEAM_DATA_ACTION, data })
            } else {
                dispatch(clearTeamData())
            }
        })

        .catch((response={}) => {
            dispatch(clearTeamData())
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
            }
        })
}

export const getTeamTypes = () => (dispatch, getState) => {

    const {
        user: {
            token = ''
        } = {},
    } = getState()

    if (!token) { return }

    return API.getTeamTypes(token)
        .then(data => {
            if (data) {
                dispatch({ type: GET_TEAM_TYPES, data })
            }
        })
        .catch((response={}) => {
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
            }
        })

}

export const getFilterCustomers = (company, searchTerm) => (dispatch, getState) => {
    const {
        user: {
            token = ''
        } = {},
    } = getState()

    if (!token) { return }

    return API.getFilterCustomers(company, searchTerm, token)
        .then(data => {
            if (data) {
                dispatch({ type: GET_FILTER_CUSTOMERS_TEAM_DATA_ACTION, data })
            }
        })
        .catch((response={}) => {
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
            }
        })
}

export const createTeam = (team=null) => (dispatch, getState) => {
    const {
        user: {
            token = ''
        } = {},
    } = getState()

    if (!token || !team) { return }

    dispatch({ type: CREATE_TEAM_ACTION })

    return API.createTeam(team, token)
        .then(() => {
            dispatch({ type: TEAM_CREATED_ACTION, team })
            // Refresh team data after successful creation
            dispatch(getTeamData(undefined, token))
        })
        .catch((response={}) => {
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(clearTeamData())
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(clearTeamData())
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                return Promise.resolve(response.code)
            }
        })
}

export const deleteTeam = (teamId) => (dispatch, getState) => {
    const {
        user: { token='' } = {},
    } = getState()

    if (!teamId || !token) { return }

    dispatch({ type: DELETE_TEAM_ACTION })

    return API.deleteTeam(teamId, token)
        .then(() => {
            dispatch({ type: TEAM_DELETED_ACTION, teamId })
            // Refresh team data after successful deletion
            dispatch(getTeamData(undefined, token))
        })
        .catch((response={}) => {
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(clearTeamData())
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(clearTeamData())
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                dispatch(clearTeamData())
                dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
            }
        })
}

export const updateTeam = (teamData) => (dispatch, getState) => {
    const {
        user: { token='' } = {},
    } = getState()

    if (!teamData || !token) { return }

    dispatch({ type: UPDATE_TEAM_ACTION })

    return API.updateTeam(teamData, token)
        .then(() => {
            dispatch({ type: TEAM_UPDATED_ACTION, teamData })
            // Refresh team data after successful deletion
            dispatch(getTeamData(undefined, token))
        })
        .catch((response={}) => {
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(clearTeamData())
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(clearTeamData())
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                dispatch(clearTeamData())
                dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
            }
        })

}

export const clearTeamData = () => {
    return dispatch => {
        dispatch({ type: CLEAR_TEAM_DATA_ACTION })
    }
}