import { useRef, useEffect } from 'react'

import * as animation from '../utils/animation'

const useTimeout = (callback, delay=60) => {
    const timeout = useRef()

    return useEffect(() => {
        const stopTimeout = () => timeout.current && animation.clearTimeout(timeout.current)

        timeout.current = animation.setTimeout(
            () => typeof callback === 'function' && callback(stopTimeout),
            delay
        )

        return stopTimeout
    })
}

export default useTimeout
