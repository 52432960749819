import React from 'react'

import NavBar from './NavBar'
import Sticky from '../Sticky/Sticky'

import './Header.sass'
import smallLogo from '../../images/logo_inline--small.svg'
import logo from '../../images/logo_inline.svg'

const Header = () => (
    <Sticky scrollLimit={ 137 } className="header-sticky">
        <header id="header">
            <NavBar />
            <h1>Foodchéri | Solution Business</h1>
            <a id="header_logo" href="/">
                <img id="header_logo_img" src={ logo } alt="Logo de Foodchéri Solution Business" />
                <img id="header_logo_img--small" src={ smallLogo } alt="Logo de Foodchéri Solution Business" />
            </a>
        </header>
    </Sticky>
)

export default React.memo(Header)
