import { BREAKPOINT_MOBILE, BREAKPOINT_DESKTOP } from '../utils/device'

import {
    SET_DEVICE_WIDTH_ACTION,
    OPEN_MODAL_ACTION,
    CLOSE_MODAL_ACTION
} from '../actions/ui'

export const DEVICE_DESKTOP = 'device-desktop'
export const DEVICE_MOBILE = 'device-mobile'
export const DEVICE_TABLET = 'device-tablet'

export const deviceType = (state = DEVICE_DESKTOP, action) => {
    let newState = state

    if (action.type === SET_DEVICE_WIDTH_ACTION) {
        if (action.width < BREAKPOINT_MOBILE)
            newState = DEVICE_MOBILE
        else if (action.width < BREAKPOINT_DESKTOP)
            newState = DEVICE_TABLET
        else
            newState = DEVICE_DESKTOP
    }

    return newState
}

export const deviceWidth = (state = null, action) => {
    let newState = state

    if (action.type === SET_DEVICE_WIDTH_ACTION)
        newState = action.width

    return newState
}

export const modal = (state={}, action) => {
    let newState = { ...state }

    if (action.type === OPEN_MODAL_ACTION) {
        newState = {
            isOpen: true,
            config: action.config || {}
        }
    } else if (action.type === CLOSE_MODAL_ACTION) {
        newState = {
            isOpen: false,
            config: {}
        }
    }

    return newState
}
