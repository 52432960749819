import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { classes as CLN } from '../../utils/classNames'

import './TextInput.sass'

export const FORM_STATUS = {
    pristine: 'pristine',
    clean: 'clean',
    dirty: 'dirty'
}

/**
 * Generic text input component
 */
export default class TextInput extends Component {
    constructor (props) {
        super(props)

        this.state = {
            value: '',
            status: FORM_STATUS.pristine
        }

        this.onChange = this.onChange.bind(this)
        this.update = this.update.bind(this)
    }

    /**
     * propTypes
     * @type Object
     * @property {string} name - the input name attribute value
     * @property {string} placeholder - a string used as placeholder for the input
     * @property {string} className - a prefix for the class name applied to the dom structure
     * @property {bool} inline - the input should have inlined style or not
     * @property {string} info - a info message that will be displayed below the input
     * @property {string} error - an error message
     * @property {function} onChange - a function that will be called on input's value change
     * @property {boolean = false} required - the autoComplete attribute value
     * @property {string=''} autoComplete - the autoComplete attribute value
     */
    static propTypes = {
        placeholder: PropTypes.string,
        className: PropTypes.string,
        inline: PropTypes.bool,
        info: PropTypes.string,
        error: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]),
        onChange: PropTypes.func
    }

    update(newState) {
        newState = { ...this.state, ...newState }
        this.setState(newState)

        this.props.onChange && this.props.onChange(newState.value)
    }

    onChange (e) {
        this.update({
            value: e.target.value,
            status: FORM_STATUS.dirty
        })
    }

    handleProps(props) {
        let { value = '', error = '' } = props

        value = typeof value === 'string' ? value : ''
        error = typeof error === 'string' ? error : ''

        if (value !== this.state.value || (!this.props.error && error)) {
            this.update({
                value,
                status: FORM_STATUS.clean
            })
        }
    }

    componentDidMount() {
        this.handleProps(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.handleProps(nextProps)
    }

    render () {
        const {
            name,
            placeholder,
            className='',
            inline,
            info,
            children = null,
            required = false,
            autoComplete = 'off',
            readOnly = false,
            maxLength
        } = this.props

        const {
            status = FORM_STATUS.pristine,
            value
        } = this.state

        const {
            onChange
        } = this

        const error = this.props.error && status !== FORM_STATUS.dirty
            ? Array.isArray(this.props.error) ? this.props.error[0] : this.props.error
            : ''

        let cName = `${ className } text-input`.trim()

        cName = CLN(cName, {
            inline,
            error,
            dirty: status === FORM_STATUS.dirty,
            clean: status === FORM_STATUS.clean,
            pristine: status === FORM_STATUS.pristine
        })

        const props = {
            autoComplete,
            placeholder,
            className: CLN(['text-input', 'field'], { 'read-only': readOnly }),
            error,
            value,
            onChange,
            type: 'text',
            required,
            readOnly,
            maxLength
        }

        if (name) { props.name = name }

        return (
            <div className={ cName }>
                <label className={ CLN(['text-input', 'label'])} htmlFor={ props.name } />
                <input { ...props } />
                <div className={ CLN(['text-input', 'underline'])} />
                <span className={ CLN(['text-input', 'error' ])}>{ error || '' }</span>
                { info
                    ? <span className={ CLN(['text-input', 'info']) }>{ info }</span>
                    : null }
                { children }
            </div>
        )
    }
}
