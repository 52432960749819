import { combineReducers } from 'redux'

import {
    GET_DASHBOARD_DATA_ACTION,
    SET_DASHBOARD_DATA_ACTION,
    CLEAR_DASHBOARD_DATA_ACTION
} from '../actions/dashboard'

export const data = (state = {}, action) => {
    let newState = { ...state }

    switch (action.type) {
    case SET_DASHBOARD_DATA_ACTION:
        newState = action.data
        break
    case GET_DASHBOARD_DATA_ACTION:
    case CLEAR_DASHBOARD_DATA_ACTION:
        newState = {}
        break
    default:
        break
    }


    return newState
}

export const isLoading = (state = false, action) => {
    let newState = state

    switch (action.type) {
    case GET_DASHBOARD_DATA_ACTION:
        newState = true
        break
    case SET_DASHBOARD_DATA_ACTION:
    case CLEAR_DASHBOARD_DATA_ACTION:
        newState = false
        break
    default:
        break
    }


    return newState
}

export default combineReducers({
    data,
    isLoading
})