import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { classes as CLN } from '../../utils/classNames'
import SelectInput from '../Inputs/SelectInput'
import TextInput from '../Inputs/TextInput'
import MultipleSelectItem from '../Inputs/MultipleSelect'
import { closeModal, openModal } from '../../actions/ui'

import { createTeam, deleteTeam, updateTeam} from '../../actions/teams'
import { getFilterCustomers } from '../../api/teams'

import './TeamForm.sass'

export const TEAM_CREATE_FORM_FIELDS = {
    'company': 'idcompagny',
    'team_type': '',
    'name': '',
    'managers': []
}

export class ConfirmDelete extends Component{
    render() {
        const {
            confirmDelete,
            closeModal,
            teamName=''
        } = this.props

        const handleClick = () => {
            confirmDelete && confirmDelete()
            closeModal && closeModal()
        }

        return(
            <div>
                <p>
                    Vous allez supprimer l'équipe <strong>{teamName}</strong> de l'entreprise FoodChéri.
                </p>
                <p>
                    Les utilisateurs seront conservés mais perdront les droits associés à cette équipe.
                </p>
                <button type="button" onClick={ handleClick }>Supprimer l'équipe</button>
            </div>
        )
    }
}

export class TeamForm extends Component{
    constructor (props) {
        super(props)

        this.state ={
            managerSearch: '',
            managerSuggestions: [],
            values: { ...TEAM_CREATE_FORM_FIELDS },
            errors: { ...TEAM_CREATE_FORM_FIELDS },
            isLoading: false,
            errorForm: false
        }
    }

    static propTypes = {
        teamData: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            managers: PropTypes.array
        })
    }

    autoComplete = (searchTerm='') => {
        if (!searchTerm || searchTerm.length < 3) {
            return
        }

        this.setState({
            managerSearch: searchTerm,
            managerSuggestions: [],
            isLoading: true
        })

        return getFilterCustomers(searchTerm, null, this.props.token)
            .then(this.handleAutoCompleteResult.bind(this, searchTerm))
    }

    handleAutoCompleteResult = (searchTerm, managerSuggestions) => {
        if (searchTerm !== this.state.managerSearch)
            return

        managerSuggestions = managerSuggestions || []
        this.setState({
            managerSuggestions,
            isLoading: false
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { values } = this.state

        if (values.team_type) {
            this.setState({ isLoading: true })
            return this.props.onSubmit && this.props.onSubmit(values)
                .catch(() => {
                    this.setState({ isLoading: false })
                })
        } else {
            this.setState({ errorForm: true })
        }
    }

    handleChange = (field, value) => {
        if (field) {

            const values = {
                ...this.state.values,
                [field]: value
            }

            this.setState({values})
        }
    }

    handleDelete = (id=null) => {
        const {
            teamData={},
            onDelete,
            closeModal
        } = this.props

        const teamName = teamData.name
            || ( teamData.team_type && teamData.team_type.label )
            || ''

        const body = (
            <ConfirmDelete
                teamName={ teamName }
                confirmDelete={ () => onDelete && onDelete(id) }
                closeModal={ closeModal }
            />
        )

        const configModal = {
            title: 'Supprimer l\'équipe',
            hasConfirmButton: true,
            id: 'team-form-delete',
            button: 'Annuler',
            body,
            onConfirm: closeModal
        }

        this.props.openModal && this.props.openModal(configModal)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading === true && this.props.isLoading === false) {
            this.props.closeModal && this.props.closeModal()

            /*
            if (!this.props.formErrors) {
                this.props.closeModal && this.props.closeModal()
            }
            */
        }
    }

    componentDidMount() {
        if(this.props.teamData) {
            const setData = {
                ...TEAM_CREATE_FORM_FIELDS,
                'id': this.props.teamData.id || 'idteam',
                'team_type': (this.props.teamData.team_type && this.props.teamData.team_type.id) || '',
                'name': this.props.teamData.name || '',
                'managers': (this.props.teamData.managers && this.props.teamData.managers.map( id => id )) || '',
            }

            this.setState({
                values: setData
            })
        }
    }

    render() {
        const {
            values={},
            errors={},
            isLoading=false,
            errorForm=false,
            managerSuggestions=[]
        } = this.state

        const {
            handleChange,
            handleSubmit,
            handleDelete,
            autoComplete
        } = this

        const {
            teamData
        } = this.props

        return(
            <form onSubmit={ handleSubmit } className={ CLN('team-create-form') }>
                <fieldset>
                    {
                        errorForm
                            ? <p className="error-msg">Veuillez sélectionner une équipe</p>
                            : null
                    }

                    <label htmlFor="input-teams">
                        <span>Équipe</span>

                        <SelectInput
                            name="input-teams"
                            items={ this.props.teamTypes }
                            defaultValue={ (teamData && teamData.team_type) || '' }
                            error={ errors.team_type }
                            placeholder="Sélectionner une équipe"
                            onChange={ handleChange.bind(this, 'team_type') }
                        />

                    </label>

                    <label htmlFor="input-name-teams">
                        <TextInput
                            placeholder="Personnalisez le nom de l’équipe (optionnel)"
                            name="input-name-teams"
                            value={ values.name || '' }
                            defaultValue={ (teamData && teamData.name) || '' }
                            error={ errors.name }
                            onChange={ handleChange.bind(this, 'name') }
                            maxLength="30"
                        />
                    </label>


                    <label className={ CLN(['input', 'managers']) } htmlFor="input-managers">
                        <span>Manager de l'équipe</span>

                        <MultipleSelectItem
                            name="input-managers"
                            suggestionItems={ managerSuggestions }
                            placeholder="Entrez le nom d'un manager (optionnel)"
                            onChange={ handleChange.bind(this, 'managers') }
                            values={ (teamData && teamData.managers) || '' }
                            refreshSuggest={ autoComplete }
                            autoCompleteIsLoading={ isLoading }
                        />

                        <p className={ CLN(['input', 'managers-info']) }>
                            Peut voir l’activité et l’historique de l’entreprise. Peut éditer les utilisateurs, les subventions et les paramètres.
                        </p>
                    </label>

                    <div className={ CLN(['team-create-form', 'wrapper-submit'], this.props.onDelete ? 'update' : 'create')}>
                        <button
                            className={ CLN(['team-create-form', 'submit']) }
                            type="submit"
                            disabled={isLoading}
                        >
                            {
                                this.props.onDelete
                                    ? 'Enregistrer'
                                    : 'Créer l\'équipe'
                            }
                        </button>

                        {
                            this.props.onDelete
                                ? <button type="button" className={ CLN(['team-create-form', 'delete']) } onClick={ () => handleDelete(teamData.id) }>Supprimer l'équipe</button>
                                : null
                        }
                    </div>

                </fieldset>
            </form>
        )
    }
}

export const CreateTeamForm = connect(

    state => ({
        formErrors: (state.teams && state.teams.formErrors) || '',
        isLoading: (state.teams && state.teams.isLoading) || false,
        token: (state.user && state.user.token) || '',
    }),
    dispatch => ({
        onSubmit: data => dispatch(createTeam(data)),
        closeModal: () => dispatch(closeModal()),
        openModal: () => dispatch(closeModal())
    })

)(TeamForm)


export const UpdateTeamForm = connect(

    state => ({
        formErrors: (state.teams && state.teams.formErrors) || '',
        isLoading: (state.teams && state.teams.isLoading) || false,
        token: (state.user && state.user.token) || '',
    }),
    dispatch => ({
        onSubmit: data => dispatch(updateTeam(data)),
        onDelete: id => dispatch(deleteTeam(id)),
        closeModal: () => dispatch(closeModal()),
        openModal: (config) => dispatch(openModal(config))
    })

)(TeamForm)
