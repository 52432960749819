import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

import * as Icons from '../Icons'

import { classes as CLN } from '../../utils/classNames'

export const Link = props => {
    const {
        exact=false,
        icon='',
        id='',
        label='',
        to='',
        className='link'
    } = props

    const Icon = (icon && Icons[icon]) || null

    return (
        <NavLink
            className={ CLN(className, 'active') }
            activeClassName={ CLN(className, 'selected') }
            exact={ exact }
            to={ to || '/' }>
            { Icon
                ? <Icon className={ CLN([className, 'icon'], id) } />
                : <i className={ CLN([className, 'icon'], id) } />}
            <span className={ CLN([className, 'label']) }>
                { label || to }
            </span>
        </NavLink>
    )
}
Link.propTypes = {
    icon: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    to: PropTypes.string,
    exact: PropTypes.bool,
    className: PropTypes.string,
}

export default React.memo(Link)
