import _isEqual from 'lodash/isEqual'
import * as API from '../api/users'

import { signOut } from './security'
import { setAccount } from './account'

import { getErrorMessage } from '../utils/errorMessages'

export const ERROR_MESSAGE_SCOPE = 'users'

export const GET_USER_DATA_ACTION = 'get-user-data'
export const SET_USER_DATA_ACTION = 'set-user-data'

export const CREATE_USER_ACTION = 'create-user'
export const USER_CREATED_ACTION = 'user-created'

export const UPDATE_USER_ACTION = 'update-user'
export const USER_UPDATED_ACTION = 'user-updated'

export const DELETE_USER_ACTION = 'delete-user'
export const USER_DELETED_ACTION = 'user-deleted'

export const CLEAR_USER_DATA_ACTION = 'clear-user-data'

export const getUserData = (company) => (dispatch, getState) => {
    const {
        user: {
            token=''
        } = {},
        dashboard={}
    } = getState()

    const lastData = dashboard.data || {}

    dispatch({ type: GET_USER_DATA_ACTION })

    if (!token) { return }
    return API.getUserData(company, token)
        .then(data => {
            if (data && Array.isArray(data.users)) {
                dispatch({ type: SET_USER_DATA_ACTION, data: data.users })
                if (data.account && !_isEqual(data.account, lastData.account)) {
                    dispatch(setAccount(data.account))
                }
            } else {
                dispatch(clearUserData())
            }
        })
        .catch((response={}) => {
            dispatch(clearUserData())
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
            }
        })
}

export const createUser = (newUser=null) => (dispatch, getState) => {
    const {
        user: { token='' } = {},
    } = getState()

    if (!newUser || !token) { return }

    dispatch({ type: CREATE_USER_ACTION })

    return API.createUser(newUser, token)
        .then(() => {
            dispatch({ type: USER_CREATED_ACTION, user: newUser })
            // Refresh user data after successful creation
            dispatch(getUserData(undefined, token))
        })
        .catch((response={}) => {
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(clearUserData())
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(clearUserData())
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                return Promise.resolve(response.code)
            }
        })
}

export const updateUser = (userId, key, value) => (dispatch, getState) => {
    const {
        user: { token='' } = {},
    } = getState()

    if (!token || !userId || !key) { return }

    userId = Array.isArray(userId) ? userId : [ userId ]

    const users = userId.map(id => ({ id, [key]: value }))

    dispatch({ type: UPDATE_USER_ACTION })

    return API.updateUser(users, token)
        .then(() => {
            dispatch({ type: USER_UPDATED_ACTION, users })
            // Refresh user data after successful update
            dispatch(getUserData(undefined, token))
        })
        .catch((response={}) => {
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(clearUserData())
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(clearUserData())
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                dispatch(clearUserData())
                dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
            }
        })
}

export const deleteUser = (userId) => (dispatch, getState) => {
    const {
        user: { token='' } = {},
    } = getState()

    if (!userId || !token) { return }

    dispatch({ type: DELETE_USER_ACTION })

    return API.deleteUser(userId, token)
        .then(() => {
            dispatch({ type: USER_DELETED_ACTION, userId })
            // Refresh user data after successful deletion
            dispatch(getUserData(undefined, token))
        })
        .catch((response={}) => {
            switch (response.code) {
            case 404: // Not found
            case 403: // Forbidden
                dispatch(clearUserData())
                dispatch(signOut(getErrorMessage('forbidden', ERROR_MESSAGE_SCOPE)))
                break
            case 401: // Unauthorized
                dispatch(clearUserData())
                dispatch(signOut(getErrorMessage('unauthorized', ERROR_MESSAGE_SCOPE)))
                break
            default:
                dispatch(clearUserData())
                dispatch(signOut(getErrorMessage('default', ERROR_MESSAGE_SCOPE)))
            }
        })
}

export const clearUserData = () => {
    return dispatch => {
        dispatch({ type: CLEAR_USER_DATA_ACTION })
    }
}
