import PropTypes from 'prop-types'
import React from 'react'
import {
    ResponsiveContainer,
    AreaChart,
    CartesianGrid,
    Area,
    XAxis,
    YAxis,
    Tooltip
} from 'recharts'

import NoChart from './NoChart'
import { DateLabelTooltip } from './Tooltip'

import {
    getWeekDate,
    getMonthDate,
} from '../../utils/datetime'

import './Area.sass'

const AreaCmp = (props = {}) => {

    const {
        className='',
        data=[],
        xDataKey='name'
    } = props

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <NoChart className={ className } />
    }

    let tickFormatter = a => a
    let tooltipProps = {}

    if (xDataKey === 'date') {
        tickFormatter = data.length <= 7
            ? getWeekDate
            : getMonthDate

        tooltipProps.content = DateLabelTooltip
    }

    return (
        <ResponsiveContainer
            className={ className + ' area-chart' }
            width="100%"
            minHeight={ 250 }>
            <AreaChart
                data={ data }
                margin={{ top: 20, right: 40, bottom: 20, left: 0 }}>
                <CartesianGrid
                    horizontal={ true }
                    vertical={ false }
                    strokeWidth={ 1 }
                    stroke="#F0F0F5"
                />
                <XAxis
                    dataKey={ xDataKey }
                    axisLine={ false }
                    tickLine={ false }
                    tickMargin={ 20 }
                    padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    domain={['auto', 'auto']}
                    tickFormatter={ tickFormatter }
                />
                <YAxis
                    tickMargin={ 10 }
                    axisLine={ false }
                    tickLine={ false }
                    padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
                />
                <defs>
                    <linearGradient id="linear" x1="50%" y1="0%" x2="50%" y2="100%">
                        <stop offset="6.85%" stopColor="rgba(100, 184, 232, 0.2)"/>
                        <stop offset="94.06%" stopColor="rgba(255, 255, 255, 0)"/>
                    </linearGradient>
                </defs>
                <Area
                    type='monotone'
                    dataKey='count'
                    stroke='#64B8E8'
                    strokeWidth={ 3 }
                    fill='url(#linear)'
                />
                <Tooltip { ...tooltipProps } />
            </AreaChart>
        </ResponsiveContainer>
    )
}

AreaCmp.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
    xDataKey: PropTypes.string
}

export default AreaCmp
