import React, { Fragment } from 'react'

import Lazy from '../../Lazy/Lazy'

import ProductHabitsRow from './ProductHabitsRow'
import NutritionalStatRow from './NutritionalStatRow'
import NutritionalTagRow from './NutritionalTagRow'
import BestProductRow from './BestProductRow'

const HabitSection = (props={}) => {

    const {
        current = {},
        previous= {},
        loading = true
    } = props

    return (
        <Fragment>
            <Lazy height={ 280 }>
                <ProductHabitsRow
                    current={ current }
                    previous={ previous }
                    loading={ loading }
                    minHeight={ 280 }
                />
            </Lazy>

            <Lazy height={ 100 }>
                <NutritionalStatRow
                    current={ current }
                    previous={ previous }
                    loading={ loading }
                    minHeight={ 100 }
                />
            </Lazy>

            <Lazy height={ 280 }>
                <NutritionalTagRow
                    current={ current }
                    previous={ previous }
                    loading={ loading }
                    minHeight={ 280 }
                />
            </Lazy>

            <Lazy height={ 320 }>
                <BestProductRow
                    current={ current }
                    previous={ previous }
                    loading={ loading }
                    minHeight={ 320 }
                />
            </Lazy>

        </Fragment>
    )
}

export default HabitSection
