import PropTypes from 'prop-types'
import React from 'react'

import { classes as CLN } from '../../utils/classNames'
import { ratio } from '../../utils/statistics'

import ProgressBar from '../Charts/ProgressBar'
import Value from './Value'
import CardInfo from './CardInfo'

import './ProgressCard.sass'

const ProgressCard = (props) => {
    const {
        label = '',
        className='',
        unit='',
        info=''
    } = props

    const value = props.value ? parseInt(props.value) : 0
    const max = props.max ? parseInt(props.max) : 0
    const percentage = ratio(value, max, true)

    return (
        <div className={ ('progress-card ' + className).trim() }>
            <div className={ CLN(['progress-card', 'head'])}>
                <div className={ CLN(['progress-card', 'head', 'value'])}>
                    <Value
                        value={ percentage }
                        type="percentage"
                        baseClass={ CLN(['progress-card', 'head', 'value']) }
                    />
                </div>
                <div className={ CLN(['progress-card', 'head', 'label'])}>
                    { label }
                </div>
                <div className={ CLN(['progress-card', 'head', 'ratio'])}>
                    {`${ value } / ${ max }`}
                </div>
            </div>
            <ProgressBar
                className={ CLN(['progress-card', 'progress-bar'])}
                value={ value }
                max={ max }
                unit={ unit }
            />
            <CardInfo info={ info || label } />
        </div>
    )
}

ProgressCard.propTypes = {
    label: PropTypes.string,
    info: PropTypes.string,
    currentValue: PropTypes.number,
    lastValue: PropTypes.number,
    className: PropTypes.string
}

export default React.memo(ProgressCard)
