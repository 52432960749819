import Cookies from 'js-cookie'

export const COOKIE_KEY = {
    auth: 'IPA'
}

/**
 * @function decodeCookieContent
 * @param { string } data - The string to decode
 * @returns { object } the JSON parsed data
 */
export const decodeCookieContent = (data) => {
    try {
        return JSON.parse(data)
    } catch (e) {
        try {
            return JSON.parse(atob(data))
        } catch (e) {
            return data
        }
    }
}
/**
 * @function encodeCookieContent
 * @param { string|object } data - The string or object to encode
 * @returns { string } The encoded string
 */
export const encodeCookieContent = (data) => {
    if (typeof data === 'string') {
        return btoa(data)
    } else {
        return btoa(JSON.stringify(data))
    }
}

export default {
    /**
     * @function get - Retrieve data from a cookie
     * @param { string } key - The name of the cookie
     * @returns { object|null } - The parsed cookie data or null if no data
     */
    get: (key) => {
        let cookieData = Cookies.get(key)
        if (cookieData)
            return decodeCookieContent(cookieData)
        else
            return null
    },
    /**
     * @function set - Store data inside a cookie
     * @param { string } key - The name of the cookie
     * @param { string|object } value - The content to store in the cookie
     * @param { boolean=true } encodeBase64 - Whether to Base64 encode the content or not
     * @returns { undefined }
     */
    set: (key, value, encodeBase64 = true, attrs) => {
        attrs = attrs || { expires: 730 }
        Cookies.set(key, encodeBase64 ? encodeCookieContent(value) : value, attrs)
    },
    /**
     * @function remove - Remove a cookie
     * @param { string } key - The name of the cookie
     * @returns { undefined }
     */
    remove: (key, attrs) => {
        Cookies.remove(key, attrs)
    }
}
