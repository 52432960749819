import Dashboard from '../Dashboard/Dashboard'
import Users from '../Users/Users'
import Teams from '../Teams/Teams'
import ROLES from '../../data/roles'

export const restrictedRoles = [
    ROLES.ROLE_DASHBOARD_B2B_MANAGER.id,
    ROLES.ROLE_SUPER_ADMIN.id
]

const ROUTES = [
    {
        id: 'dashboard',
        path: '/dashboard/:companyId?',
        to: '/dashboard',
        label: 'dashboard',
        isActive: true,
        exact: true,
        component: Dashboard,
        icon: 'DashboardIcon',
        hasMenuLink: true,
        protected: true,
    },
    {
        id: 'users',
        path: '/utilisateurs/:companyId?',
        to: '/utilisateurs',
        label: 'utilisateurs',
        isActive: true,
        component: Users,
        icon: 'UserIcon',
        hasMenuLink: true,
        protected: true,
        restrictedRoles,
    },
    {
        id: 'teams',
        path: '/equipes',
        to: '/equipes',
        label: 'équipes',
        isActive: true,
        component: Teams,
        icon: 'TeamIcon',
        hasMenuLink: true,
        protected: true,
        restrictedRoles,
    },
    {
        id: 'discounts',
        path: '/subventions',
        to: '/subventions',
        label: 'subventions',
        isActive: false,
        icon: 'BuildingIcon',
        hasMenuLink: false,
        protected: true,
        restrictedRoles,
    },
    {
        id: 'history',
        path: '/historique',
        to: '/historique',
        label: 'historique',
        isActive: false,
        icon: 'HistoryIcon',
        hasMenuLink: false,
        protected: true,
        restrictedRoles,
    },
    {
        id: 'params',
        path: '/parametres',
        to: '/parametres',
        label: 'parametres',
        isActive: false,
        icon: 'ParamsIcon',
        hasMenuLink: false,
        protected: true,
        restrictedRoles,
    },
]

export default ROUTES
