import _ from 'lodash'
import device from '../utils/device'

export const SET_DEVICE_WIDTH_ACTION = 'set-device-type'

export const OPEN_MODAL_ACTION = 'open-modal'
export const CLOSE_MODAL_ACTION = 'close-modal'

let hasRegisterWindowResizeListener = false

export const checkDeviceType = () => {
    return (dispatch) => {
        const onResize = _.throttle(() => {
            dispatch({
                type: SET_DEVICE_WIDTH_ACTION,
                width: device.getScreenWidth(true)
            })
        }, 100)

        if (!hasRegisterWindowResizeListener) {
            window.addEventListener('resize', onResize)
            hasRegisterWindowResizeListener = true
        }

        onResize()
    }
}

export const openModal = (config={}) => ({
    type: OPEN_MODAL_ACTION,
    config
})

export const closeModal = () => ({
    type: CLOSE_MODAL_ACTION
})
