import LazyLoad from 'react-lazyload'
import React, { Fragment } from 'react'

export const DEFAULT_LAZY_PROPS = {
    height: 100,
    unmountIfInvisible: true,
    once: true,
    offset: -100
}

const Lazy = props => (
    <LazyLoad { ...DEFAULT_LAZY_PROPS } { ...props }>
        {
            Array.isArray(props.children)
                ? <Fragment>{ props.children }</Fragment>
                : props.children
        }
    </LazyLoad>
)

export default Lazy
