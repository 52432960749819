import jwt from 'jsonwebtoken'

import URL from './urls'

const getFetchParams = {
    method: 'GET',
    mode: 'cors',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*'
    },
}

const postFetchParams = {
    ...getFetchParams,
    headers: {
        ...getFetchParams.headers,
        'Content-Type': 'application/json',
    },
    method: 'POST'
}

export const getUserData = (company, token='') => {
    const tokenData = jwt.decode(token) || {}
    company = company || tokenData.id_company || ''

    const url =  URL.getUserData.replace(':company', company)

    const params = {
        ...getFetchParams,
        headers: {
            ...getFetchParams.headers,
            'Authorization': 'Bearer ' + token
        }
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
        .then(json => {
            if (!json || typeof json !== 'object') { return {} }

            if (json.code && json.code !== 200) {
                return Promise.reject(json)
            }

            const data = Array.isArray(json) && json.length
                ? json[0].data
                : json.data

            return data || {}
        })
}

export const createUser = (users, token='') => {
    users = Array.isArray(users) ? users : [ users ]
    const tokenData = jwt.decode(token) || {}
    const company = tokenData.id_company || ''

    const url =  URL.createUser.replace(':company', company)

    const params = {
        ...postFetchParams,
        headers: {
            ...postFetchParams.headers,
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ users })
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
        .then(json => {
            if (!json || typeof json !== 'object') { return {} }

            if (json.code && json.code !== 200) {
                return Promise.reject(json)
            }

            const data = Array.isArray(json) && json.length
                ? json[0].data
                : json.data

            return data || {}
        })
}

export const updateUser = (users, token='') => {
    users = Array.isArray(users) ? users : [ users ]
    const tokenData = jwt.decode(token) || {}
    const company = tokenData.id_company || ''

    const url =  URL.updateUser.replace(':company', company)

    const params = {
        ...postFetchParams,
        headers: {
            ...postFetchParams.headers,
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ users })
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
        .then(json => {
            if (!json || typeof json !== 'object') { return {} }

            if (json.code && json.code !== 200) {
                return Promise.reject(json)
            }

            const data = Array.isArray(json) && json.length
                ? json[0].data
                : json.data

            return data || {}
        })
}

export const deleteUser = (users, token='') => {
    users = Array.isArray(users) ? users : [ users ]
    const tokenData = jwt.decode(token) || {}
    const company = tokenData.id_company || ''

    const url =  URL.deleteUser.replace(':company', company)

    const params = {
        ...postFetchParams,
        headers: {
            ...postFetchParams.headers,
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ users })
    }

    return fetch(url, params)
        .then(response => {
            return response.ok
                ? response.json()
                : { code: response.status }
        })
        .then(json => {
            if (!json || typeof json !== 'object') { return {} }

            if (json.code && json.code !== 200) {
                return Promise.reject(json)
            }

            const data = Array.isArray(json) && json.length
                ? json[0].data
                : json.data

            return data || {}
        })
}
