import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

export const HEAD_TITLE_ID = 'head-title'
export const headTitle = document.getElementById(HEAD_TITLE_ID)

const Title = ({ title = '' }) => (
    ReactDOM.createPortal(title ? ' | ' + title : '', headTitle)
)

Title.propTypes = {
    title: PropTypes.string.isRequired
}

export default React.memo(Title)
