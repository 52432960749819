import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { CaretIcon } from '../Icons'

import { classes as CLN } from '../../utils/classNames'

import './SelectInput.sass'

export const SelectInputItem = (props={}) => {
    const {
        item={},
        className='select-input_list_item',
        handleClick,
        disabled=false,
        listOpen=false,
        isEmpty=false,
        dataId,
        children=null
    } = props

    return (
        <div
            data-id={ dataId }
            title={ item.label }
            onClick={ handleClick }
            className={ CLN(className, { disabled, listOpen, empty: isEmpty }) } >
            { item.label }
            { children }
        </div>
    )
}

export const SelectInputList = (props={}) => {
    const {
        items = [],
        handleClick,
        show=true,
        dataId
    } = props

    if (!items || !Array.isArray(items)) {
        return null
    }

    const className = props.className || 'select-input_list'

    return (
        <div className={ CLN(className, { open: show }) }>
            {
                items.map(item => (
                    <SelectInputItem
                        data-id={ dataId }
                        key={ `select-input_list_${ item.id || item.label }` }
                        item={ item }
                        className={ CLN([className, 'item']) }
                        handleClick={ () => handleClick(item) }>
                        {
                            item.description
                                ? <p>{ item.description }</p>
                                : null
                        }
                    </SelectInputItem>
                ))
            }
        </div>
    )
}

export default class SelectInputSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listOpen: false,
            selected: this.props.defaultValue || {},

        }

        this.handleClick = this.handleClick.bind(this)
        this.clickTrigger = this.clickTrigger.bind(this)
        this.handleWindowClick = this.handleWindowClick.bind(this)
    }

    static propTypes = {
        updateSelected: PropTypes.bool,
        onChange: PropTypes.func,
        onMouseLeave: PropTypes.func,
        items: PropTypes.array,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        name: PropTypes.string.isRequired
    }

    handleClick(selected) {
        selected = selected && this.props.items.some(item => item.id === selected.id)
            ? selected
            : null

        this.setState({ listOpen: false, selected })

        this.props.onChange && this.props.onChange((selected && selected.id) || null)
    }

    clickTrigger (shouldOpen) {
        const listOpen = typeof shouldOpen === 'boolean'
            ? shouldOpen
            : !this.state.listOpen

        this.setState({ listOpen })
    }

    componentDidUpdate(lastProps, lastState) {
        if (lastState.listOpen && _.isEqual(lastProps.selected, this.props.selected) === false) {
            this.setState({ listOpen: false })
        }

        if (lastState.listOpen && !lastProps.readOnly && this.props.readOnly) {
            this.setState({ listOpen: false })
        }

        if (!lastState.listOpen && this.state.listOpen) {
            window.addEventListener
                && window.addEventListener('click', this.handleWindowClick)
        } else if (lastState.listOpen && !this.state.listOpen) {
            window.removeEventListener
                && window.removeEventListener('click', this.handleWindowClick)
        }
    }

    handleWindowClick (e) {
        if (this.state.listOpen === true
                && e.target
                && e.target.dataset
                && e.target.dataset.id !== `select-input_${ CLN(this.props.name) }`) {
            e.preventDefault()
            e.stopPropagation()
            this.clickTrigger(false)
        }
    }

    componentWillUnmount () {
        window.removeEventListener
            && window.removeEventListener('click', this.handleWindowClick)
    }

    render () {
        const {
            handleClick,
            clickTrigger
        } = this

        const {
            listOpen = false
        } = this.state

        const {
            items = [],
            disabled=false,
            className='',
            placeholder='',
            name,
            readOnly=false,
            onMouseLeave,
            updateSelected=true
        } = this.props

        const selected = updateSelected && this.state.selected
            ? this.state.selected
            : {}

        return (
            <div
                onMouseLeave={ onMouseLeave }
                data-id={ `select-input_${ CLN(name) }` }
                className={ className + ' ' + CLN(['select-input'], { open: listOpen }) }>

                <input
                    type="hidden"
                    name={ name }
                    value={ selected.id || '' } />

                <SelectInputItem
                    dataId={ `select-input_${ CLN(name) }` }
                    handleClick={ readOnly ? undefined : clickTrigger }
                    item={{ label: selected && selected.label ? selected.label : placeholder || '' }}
                    className={ CLN([ 'select-input', 'input' ]) }
                    disabled={ disabled || readOnly }
                    listOpen={ listOpen }
                    isEmpty={ !(selected && selected.label) }>
                    <CaretIcon className={ CLN(['select-input', 'input', 'caret'], { open: listOpen }) } />
                </SelectInputItem>

                <SelectInputList
                    dataId={ `select-input_${ CLN(name) }` }
                    items={ items }
                    handleClick={ handleClick }
                    className={ CLN(['select-input', 'list']) }
                    show={ listOpen }
                />
            </div>
        )
    }
}
