export const JOINER = '_'
export const MODIFIER = '--'
/**
 * @function modify
 * Adds one or more modifier string to a base string
 * @param { string|string[]|object} modifiers A modifier / list / conditional list of string modifiers
 * @param { string } baseClass The base string to modify
 * @property
 * @returns { string } A string
 * @example
 * // return 'kebab kebab--salade-tomates-oignons'
 * classChain('saladeTomatesOignons', 'kebab')
 * // return 'kebab kebab--salade kebab--tomates kebab--oignons'
 * classChain({'salade': true, 'tomates': true, 'oignons': false}, 'kebab')
 * // return 'kebab kebab--salade kebab--tomates kebab--oignons'
 * classChain(['salade', 'tomates', 'oignons'], 'kebab')
 */
export const modify = (modifiers = {}, baseClass) => {
    baseClass = baseClass ? kebabify(baseClass) : ''
    modifiers = typeof modifiers === 'string' ? [ modifiers ] : modifiers

    if (!baseClass || !modifiers) {
        return baseClass || ''
    }

    let output = `${baseClass} `

    if (Array.isArray(modifiers)) {
        modifiers = modifiers.map(m => m && `${baseClass}${MODIFIER}${kebabify(m)}`).join(' ')
        output += modifiers
    } else if (typeof modifiers === 'object') {
        Object.keys(modifiers).map(modifier => {
            if (modifiers[modifier]) {
                output += `${baseClass}${MODIFIER}${kebabify(modifier)} `
            }

            return output
        })
    }

    return output.toLowerCase().trim()
}

/**
 * @function kebabify
 * Format a string into kebab case
 * @param { string } string A string
 * @returns { string } A kebab-case formatted string
 * @example
 * // return 'salade-tomates-oignons'
 * kebabify('salade tomates oignons')
 */
export const kebabify = (string = '') => String(string).replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase()

/**
 * @function classChain
 * Format an array of strings into kebab case and chain them using underscores
 * @param { string[] } An array of strings to be chained
 * @returns { string } A string
 * @example
 * // return 'salade-mixte_tomates_oignons'
 * classChain(['salade mixte', 'tomates', 'oignons'])
 */
export const classChain = (classNames = []) => {
    classNames = typeof classNames === 'string' ? [ classNames ] : classNames

    return Array.isArray(classNames) && classNames.map(kebabify).join(JOINER)
}

/**
 * @function classes
 * Format an array of strings into kebab case and chain them using underscores eventually duplicating and adding a modifier
 * @param { string|string[] } classNames An array of strings to be chained
 * @param { string|string[]|object} modifiers A modifier / list / conditional list of modifiers
 * @property
 * @returns { string } A string
 * @example
 * // return 'salade-mixte_tomates_oignons'
 * classChain(['salade mixte', 'tomates', 'oignons'])
 * // return 'kebab kebab--salade kebab--tomates kebab--oignons'
 * classChain('kebab', {'salade': true, 'tomates': true, 'oignons': false})
 * // return 'kebab kebab--salade kebab--tomates kebab--oignons'
 * classChain('kebab', ['salade', 'tomates', 'oignons'])
 */
export const classes = (classNames = '', modifiers = '') => {
    classNames = typeof classNames === 'string' ? [ classNames ] : classNames
    modifiers = typeof modifiers === 'string' ? [ modifiers ] : modifiers

    if (Array.isArray(classNames)) {
        const chainedString = classChain(classNames) || ''

        return chainedString && modifiers
            ? modify(modifiers, chainedString)
            : chainedString
    }

    throw new TypeError('Bad arguments passed to classes function')
}
