import PropTypes from 'prop-types'
import React from 'react'

import { classes as CLN } from '../../utils/classNames'

import Value from '../Card/Value'

import './ProgressBar.sass'

const ProgressBar = (props={}) => {
    const {
        className='',
        unit=''
    } = props

    const value = props.value ? parseInt(props.value) : 0
    const max = props.max ? parseInt(props.max) : 100

    let percentage = parseInt(value / max * 100)
    let title = `[ ${ percentage }% ]: ${ value }/${ max } ${ unit }`

    if (!percentage || percentage === Infinity || isNaN(percentage)) {
        percentage = 0
        title = undefined
    }

    let fillModifier = 'medium'

    if (percentage >= 99) {
        fillModifier = 'full'
    } else if (percentage >= 50) {
        fillModifier = 'high'
    } else if (percentage <= 20) {
        fillModifier = 'low'
    }

    return (
        <div
            title={ title }
            className={ className + ' ' + CLN(['progress-bar']) }>
            <progress
                value={ value }
                max={ max }
                className={ CLN(['progress-bar', 'progress']) }>
                <Value
                    value={ percentage }
                    type="percentage"
                    baseClass={ CLN(['progress-bar', 'progress', 'value']) }
                />
            </progress>
            <div className={ CLN(['progress-bar', 'display'], { full: percentage >= 100 }) }>
                <div
                    style={{ width: Math.min(100, percentage) + '%' }}
                    className={ CLN(['progress-bar', 'display', 'fill'], fillModifier) }>
                </div>
            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    className: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default React.memo(ProgressBar)
