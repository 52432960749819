import { combineReducers } from 'redux'

import {
    GET_TEAM_DATA_ACTION,
    SET_TEAM_DATA_ACTION,
    CLEAR_TEAM_DATA_ACTION,
    GET_TEAM_TYPES,
    DELETE_TEAM_ACTION,
    TEAM_DELETED_ACTION,
    GET_FILTER_CUSTOMERS_TEAM_DATA_ACTION
} from '../actions/teams'

export const data = (state = [], action) => {
    let newState = Array.isArray(state) ? [ ...state ] : []

    switch (action.type) {
    case SET_TEAM_DATA_ACTION:
        newState = [...action.data]
        break
    case CLEAR_TEAM_DATA_ACTION:
        newState = []
        break
    default:
        break
    }

    return newState
}

export const teamListData = (state = [], action) => {
    let newState = Array.isArray(state) ? [ ...state ] : []
    let team
    const NO_TEAM = { label: 'Sans équipe', id: null, isDefault: true, className: 'empty-team' }

    switch (action.type) {
    case SET_TEAM_DATA_ACTION:
        if (Array.isArray(action.data) && action.data.length) {
            team = [
                ...action.data.map(team => {
                    return {
                        label: team.name ? team.name : (team.team_type && team.team_type.label) || '',
                        id: team.id,
                        isDefault: false
                    }
                }),
                NO_TEAM
            ]

            newState = [ ...team ]
        } else {
            newState = [ NO_TEAM ]
        }
        break
    default:
        break
    }

    return newState
}

export const teamTypes = (state = [], action) => {
    let newState = Array.isArray(state) ? [ ...state ] : []

    switch (action.type) {
    case GET_TEAM_TYPES:
        newState = [...action.data]
        break
    default:
        break
    }

    return newState
}

export const filterCustomers = (state = [], action) => {
    let newState = Array.isArray(state) ? [ ...state ] : []

    switch (action.type) {
    case GET_FILTER_CUSTOMERS_TEAM_DATA_ACTION:
        newState = [...action.data]
        break
    default:
        break
    }

    return  newState
}

export const isLoading = (state = false, action) => {
    let newState = state

    switch (action.type) {
    case GET_TEAM_DATA_ACTION:
    case DELETE_TEAM_ACTION:
        newState = true
        break
    case SET_TEAM_DATA_ACTION:
    case CLEAR_TEAM_DATA_ACTION:
    case TEAM_DELETED_ACTION:
        newState = false
        break
    default:
        break
    }

    return newState
}

export default combineReducers({
    data,
    isLoading,
    teamTypes,
    teamListData,
    filterCustomers
})