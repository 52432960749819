import React from 'react'

import { DashboardBox } from '../Dashboard'

import ChartCard from '../../Card/ChartCard'

import CircularProgress from '../../Charts/CircularProgress'
import NoChart from '../../Charts/NoChart'

import { ratio } from '../../../utils/statistics'

const NutritionalTagRow = (props={}) => {
    const {
        loading=true,
        minHeight='auto'
    } = props

    const current = props.current || {}

    const hasTags = current.products
        && Array.isArray(current.products.preferences)
        && current.products.preferences.some(p => p.value > 0)

    return (
        <div className="row">
            <div className="col-xs-12 col-lg-12">
                <DashboardBox style={{ minHeight }}loading={ loading }>
                    <ChartCard
                        className="dashboard_card dashboard_card--nutritional-tags"
                        info="nutritional-tags"
                        label="Habitudes alimentaires">
                        <div className="row dashboard_card_chart dashboard_card_chart--nutritional-tags between-xs">
                            {
                                hasTags
                                    ? current.products.preferences
                                        .map(p => (
                                            <div
                                                key={ 'circular-progress_' + p.name }
                                                className="col-xs-6 col-sm-3">
                                                <DashboardBox style={{ minHeight }}loading={ loading }>
                                                    <CircularProgress
                                                        value={ ratio(p.value, current.products.count, true) }
                                                        name={ p.name }
                                                    />
                                                </DashboardBox>
                                            </div>
                                        ))
                                    : <NoChart />
                            }
                        </div>
                    </ChartCard>
                </DashboardBox>
            </div>
        </div>
    )
}

export default NutritionalTagRow
