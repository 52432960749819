import PropTypes from 'prop-types'
import React from 'react'

import {
    InfoIcon
} from '../Icons'

import { classes as CLN } from '../../utils/classNames'

import infoContent from '../../data/dashboardInfo'

import './CardInfo.sass'

const CardInfo = (props = {}) => {
    const {
        lang='fr'
    } = props

    const info = infoContent[lang]
        && typeof infoContent[lang][props.info] !== 'undefined'
        ? infoContent[lang][props.info]
        : props.info

    if (!info) {
        return null
    }

    return (
        <div className={ CLN(['card-info'])}>
            <InfoIcon className={ CLN(['card-info', 'trigger'])} />
            <p className={ 'pop-in ' + CLN(['card-info', 'tooltip'])}>
                { info }
            </p>
        </div>
    )
}

CardInfo.propTypes = {
    lang: PropTypes.string,
    info: PropTypes.string
}

export default React.memo(CardInfo)
