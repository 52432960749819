import moment from 'moment'
import 'moment/locale/fr'

export const getWeekDate = (date) => moment(date).isValid() ? moment(date).format('dddd DD') : date
export const getMonthDate = (date) => moment(date).isValid() ? moment(date).format('DD') : date
export const getReadableDate = (date) => moment(date).isValid() ? moment(date).format('dddd D MMMM') : date


/**
 * Function to retrieve the start / end of a given date month
 * @param { string|number } date - The selected datetime
 * @returns { object }
 * @prop { string } start - The first day of the month datestring
 * @prop { string } end - The last day of the month (or today if the month is not over) datestring
 */
export const getMonthDateRange = (date) => {

    const startDate = moment.utc(date)

    if (!startDate.isValid()) { return null }

    startDate.date(1).hours(0).minutes(0).seconds(0).milliseconds(0)

    let endDate = moment.utc(startDate).endOf('month')

    if (moment.utc().isBefore(endDate)) {
        endDate = moment.utc()
    }

    return { start: startDate.format(), end: endDate.format() }
}

/**
 * Function to retrieve if a given date month is ongoing or not
 * @param { string|number } date - The selected datetime
 * @returns { boolean }
 */
export const getIsCurrentPeriodOngoing = date => {
    const startDate = date ? moment.utc(date) : moment.utc()
    if (!startDate.isValid()) { return false }
    let endDate = moment.utc(startDate).endOf('month')

    return moment().utc().isBefore(endDate)
}
