import ERROR_MESSAGES from '../data/errorMessages'

export const getErrorMessage = (id, scope='generic') => {

    const messages = typeof scope === 'string' && ERROR_MESSAGES[scope]
        ? ERROR_MESSAGES[scope]
        : ERROR_MESSAGES.generic

    const message = typeof id === 'string' && messages[id]
        ? messages[id]
        : messages.default

    return message || ''
}
