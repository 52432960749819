import PropTypes from 'prop-types'
import React from 'react'

import './Header.sass'

export const SmallHeader = (props={}) => {

    const {
        name=''
    } = props

    return (
        <header id="small-header">
            <a href="https://www.foodcheri.com">Voir la carte</a>
            Dashboard
            { name ? ` de ${ name }` : ''}
        </header>
    )
}

SmallHeader.propTypes = {
    name: PropTypes.string
}

export default React.memo(SmallHeader)
