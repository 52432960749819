export const sum = (numbers=[]) => {
    if (Array.isArray(numbers) && numbers.length) {
        const result = numbers.reduce((sum, number) => {
            number = parseFloat(number)
            if (!isNaN(number)) { sum += number }
            return sum
        }, 0)

        return result
    }

    return 0
}

export const ratio = (value=1, total=1, percentage=false) => {
    value = parseFloat(value)
    total = parseFloat(total)

    if(isNaN(value) || isNaN(total)) {
        return 0
    }

    const result = value / total

    if (isNaN(result) || result === Infinity) {
        return 0
    }

    return percentage === true
        ? Math.round(result * 100)
        : result
}
