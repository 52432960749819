import PropTypes from 'prop-types'
import React from 'react'

export const NoCard = ({ title='', body='', className='no-card' }) => (
    <div className={ className }>
        <div className='card_body'>
            <h4>{ title }</h4>
            <p>{ body }</p>
        </div>
    </div>
)

NoCard.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    className: PropTypes.string,
}

export default React.memo(NoCard)
