import jwt from 'jsonwebtoken'
import cookie, { COOKIE_KEY } from '../utils/cookies'

import URL from '../api/urls'
import { signIn as signInApi } from '../api/security'

export const AUTHORIZE_ACTION = 'authorize'
export const SIGN_IN_ACTION = 'sign-in'
export const SIGN_OUT_ACTION = 'sign-out'

export const API_AUTH_COOKIE_ATTRS = { domain: URL.cookieDomain, expires: 730 }
export const API_ERROR_MESSAGES = {
    '401': 'Mauvais identifiants',
    '500': 'Une erreur est survenue',
    'default': 'Une erreur est survenue'
}

export const setApiTokenCookie = ({ token=null }={}) => {
    token && cookie.set(COOKIE_KEY.auth, token, false, API_AUTH_COOKIE_ATTRS)
}
export const removeApiTokenCookie = function() { cookie.remove(COOKIE_KEY.auth, API_AUTH_COOKIE_ATTRS) }

export const authorize = () => {
    return dispatch => {
        const token = cookie.get(COOKIE_KEY.auth)
        const tokenData = jwt.decode(token) || {}

        if (token) {
            return dispatch({
                type: AUTHORIZE_ACTION,
                user: { 
                    token, 
                    roles: tokenData.roles
                }
            })
        }

        dispatch(signOut('Connectez vous pour acceder à cette page'))
    }
}

export const signIn = (username, password) => {
    return dispatch => {
        return signInApi(username, password)
            .then((data) => {
                const tokenData = jwt.decode(data.token) || {}

                if (data && data.token) {
                    const user = {
                        token: data.token,
                        username,
                        roles: tokenData.roles
                    }
                    setApiTokenCookie(data)
                    dispatch({
                        type: SIGN_IN_ACTION,
                        user
                    })
                } else {
                    const message = data.code && API_ERROR_MESSAGES[data.code]
                        ? API_ERROR_MESSAGES[String(data.code)] : data.message

                    dispatch(signOut(message))
                }
            })
            .catch(() => {
                dispatch(signOut())
            })
    }
}

export const signOut = (message=API_ERROR_MESSAGES.default) => {
    removeApiTokenCookie()
    
    return {
        type: SIGN_OUT_ACTION,
        message
    }
}
