import PropTypes from 'prop-types'
import React from 'react'
import Svg from 'react-inlinesvg'

Svg.displayName = 'Svg'

/**
 * Function to reate an Icon component given a src image file and some props
 * @param { string } src - The image file src
 * @param { displayName='GenericIcon' } displayName - The returned component display name
 */
export const createIcon = (src, displayName) =>{
    const IconComponent = props => src ? <Icon src={ src } { ...props } /> : null
    IconComponent.displayName = displayName || 'GenericIcon'
    Icon.propTypes = { src: PropTypes.string }

    return React.memo(IconComponent)
}

/**
 * Renders an inline Icon component
 */
export const Icon = props => props.src ? <Svg { ...props } /> : null
Icon.displayName = 'Icon'
Icon.propTypes = {
    src: PropTypes.string
}

export default Icon
