import React from 'react'

import {
    LinkBlankIcon
} from '../Icons'

const NavBar = () => (
    <nav className="navbar">
        <div className="navbar_section navbar_section--left">
            <a className="navbar_section_item" href="https://www.foodcheri.com">
                À la carte
            </a>
            <a className="navbar_section_item" href="https://www.foodcheri.com/values">
                Nos engagements
            </a>
        </div>
        <div className="navbar_section navbar_section--right">
            <a rel="noopener noreferrer" target="_blank" className="navbar_section_item navbar_section_item--button" href="https://www.foodcheri.com">
                Commander&nbsp;
                <LinkBlankIcon className="navbar_section_item_icon" />
            </a>
        </div>
    </nav>
)

export default React.memo(NavBar)
