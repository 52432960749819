import React from 'react'
import './TableLoader.sass'
import Svg from 'react-inlinesvg'
import loader from '../../images/loading.svg'
import { classes as CLN } from '../../utils/classNames'

Svg.displayName = 'Svg'

export const Loader = (props = {}) => (<Svg className={ CLN(['circle-loader']) }  src={ loader } { ...props } />)

export default Loader
