import HashIds from 'hashids'
const hashids = new HashIds('carrot-cake!', 10)

const toNumberWithSymbolString = (defaultSymbol = '', defaultRounded=false) => {
    return (value, symbol = defaultSymbol, rounded = defaultRounded) => {
        value = typeof value === 'string' && value.indexOf(',') >= 0 ? value.replace(',','.') : value
        if (isNaN(parseFloat(value, 10))) {
            return '••••'
        }
        const result = parseFloat(value, 10)

        return rounded
            ? Math.round(result) + symbol
            : result.toFixed(2).replace('.',',') + symbol
    }
}

/**
 * @function toCurrencyString
 * Format a number into a string representing a currency amount
 * @param { number } value A numeric value (integer or float)
 * @return { string } A numeric string with two decimal digits and the currency symbol
 * @example
 * // returns '10,90 €'
 * toCurrencyString(10.9)
 */
export const toCurrencyString = toNumberWithSymbolString('€')
/**
 * @function toPercentageString
 * Format a number into a string representing a percentage
 * @param { number } value A numeric value (integer or float)
 * @returns { string } A numeric string with two decimal digits and the percentage symbol (e.g. '5,50 %')
 * @example
 * // return '5,50 %'
 * toPercentageString(5.5)
 */
export const toPercentageString = toNumberWithSymbolString('%', true)
/**
 * @function kebabify
 * Format a string into kebab case
 * @param { string } string A string
 * @returns { string } A kebab-case formatted string
 * @example
 * // return 'salade-tomates-oignons'
 * kebabify('salade tomates oignons')
 */
export const kebabify = (string = '') => String(string)
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
/**
 * @function classChain
 * Format an array of strings into kebab case and chain them using underscores
 * @param { string[] } An array of strings to be chained
 * @param { string } An optional string modifier to be chained at the end of the string
 * @returns { string } A string
 * @example
 * // return 'salade-mixte_tomates_oignons'
 * classChain(['salade mixte', 'tomates', 'oignons'])
 * // return 'salade-mixte_tomates_oignons salade-mixte_tomates_oignons--harissa'
 * classChain(['salade mixte', 'tomates', 'oignons'], 'harissa')
 */
export const classChain = (classNames = [], modifier = '') => {
    if (Array.isArray(classNames) && classNames.length) {
        let chainedString = classNames.map(kebabify).join('_')
        if (modifier && kebabify(modifier)) {
            chainedString += ` ${chainedString}--${kebabify(modifier)}`
        }

        return chainedString
    }
}

/**
 * @function capitalizeFirst
 * Capitalize only the first letter of a given string
 * @param { string } The string to modify
 * @returns { string } The modified string
 * @example
 * // return 'Hello world'
 * capitalizeFirst('hello world')
 * capitalizeFirst('Hello World')
 * capitalizeFirst('HELLO WORLD')
 */
export const capitalizeFirst = (str = '') => {
    if (typeof str !== 'string' || str.length === 0) {
        return str
    }

    return str
        .toLowerCase()
        .split('')
        .map((c, i) => i === 0 ? c.toUpperCase() : c)
        .join('')
}

export const obfuscate = {
    stringify: (str='') => (
        String(str) ? hashids.encode(String(str)) : ''
    ),
    parse: (str='') => (
        (String(str)
        && hashids.decode(String(str))
        && hashids.decode(String(str))[0])
        || ''
    )
}
