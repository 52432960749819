import { connect } from 'react-redux'
import React from 'react'
import {
    HashRouter as Router,
    Redirect,
    Route,
    Switch
} from 'react-router-dom'

import Header from './Header'
import SmallHeader from './SmallHeader'
import PageNotFound from './PageNotFound'
import Modal from './Modal'
import MainMenu from '../MainMenu'

import ROUTES from './appRoutes'
import {
    PrivateRoute,
    SignInRoute,
    SignOutRoute
} from '../Routes'

import { checkDeviceType } from '../../actions/ui'
import { authorize } from '../../actions/security'

import './App.sass'

export const filterByRestrictedRoles = (roles=[], route=[]) => (
    !Array.isArray(route.restrictedRoles)
    || route.restrictedRoles.some(role=> roles.includes(role))
)

export class App extends React.Component {

    componentDidMount () {
        this.props.checkDeviceType && this.props.checkDeviceType()
        this.props.authorize && this.props.authorize()
    }

    render () {
        const {
            account={},
            user={}
        } = this.props

        return (
            <Router>
                <div>
                    <Header />
                    <SmallHeader name={ (account && account.name) || '' } />
                    <Modal />
                    {
                        user && user.token
                            ? <MainMenu routes={ ROUTES } account={ account } />
                            : null
                    }
                    <div id="main">
                        <Switch>
                            <SignInRoute path="/login" exact={ true } user={ user } />
                            <SignOutRoute path="/logout" exact={ true } />
                            {
                                ROUTES
                                    .filter(route => route.protected && route.isActive)
                                    .filter(filterByRestrictedRoles.bind(this, user.roles))
                                    .map(route => (
                                        <PrivateRoute
                                            key={ 'hash_route_' + route.id }
                                            exact={ route.exact || false }
                                            path={ route.path }
                                            component={ route.component || null }
                                            isAuthenticated={ !!(user && user.token) }
                                        />
                                    ))
                            }
                            <Route path="/" exact={ true } render={ () => <Redirect to="/dashboard" /> }/>
                            <Route component={ PageNotFound } />
                        </Switch>
                    </div>
                </div>
            </Router>
        )
    }
}

export default connect(
    state => ({
        user: state.user || null,
        account: state.account || {}
    }),
    dispatch => ({
        authorize: () => dispatch(authorize()),
        checkDeviceType: () => dispatch(checkDeviceType())
    })
)(App)
