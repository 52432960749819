import CircularProgressBar from 'react-circular-progressbar'
import PropTypes from 'prop-types'
import React from 'react'

import {
    GlutenIcon,
    LactoseIcon,
    CorianderIcon,
    HotIcon
} from '../Icons'
import Value from '../Card/Value'

import 'react-circular-progressbar/dist/styles.css'
import './CircularProgress.sass'

export const DEFAULT_COLOR = '#000'

export const CONFIG = {
    glutenFree: {
        label: 'Sans gluten',
        color: '#EBA900',
        Icon: GlutenIcon
    },
    lactoseFree: {
        label: 'Sans lactose',
        color: '#377AC9',
        Icon: LactoseIcon
    },
    corianderFree: {
        label: 'Sans coriandre',
        color: '#4DBE4B',
        Icon: CorianderIcon
    },
    needsCooking: {
        label: 'À réchauffer',
        color: '#F25151',
        Icon: HotIcon
    }
}

const CircularProgress = (props={}) => {

    const {
        value=0,
        name=''
    } = props

    const config = CONFIG[name] || {}

    const stroke = config.color || DEFAULT_COLOR

    return (
        <div className="circular-progress">
            <CircularProgressBar
                percentage={ value }
                className="circular-progress_chart"
                initialAnimation={ true }
                styles={{
                    path: {
                        stroke,
                        transition: 'stroke-dashoffset 2500ms ease-in-out 0s'
                    }
                }}
            />
            <div className="circular-progress_value">
                <Value
                    baseClass="circular-progress_value"
                    value={ parseInt(value) }
                    type="percentage"
                />
            </div>
            <div className="circular-progress_label">
                { config.label || '???' }
            </div>
            <div className="circular-progress_icon" style={{ backgroundColor: stroke }}>
                {
                    config.Icon
                        ? <config.Icon className="circular-progress_icon_img" />
                        : null
                }
            </div>
        </div>
    )
}

CircularProgress.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string
}

export default React.memo(CircularProgress)
