import cloudinaryCore from './cloudinary'

import URLS from '../api/urls'

export const defaultProductImageId = '0'
export const defaultProductImageFile = 'ca_mijote.jpg'

/**
 * @function getLogoUrl
 * @description Function to conditionnaly retrieve the full company logo image URL
 * @param { string='' } fileName - the whole or part of the company logo image URL
 * @returns { string } The logo image URL
 */
export const getCompanyLogoUrl = (fileName = '') => {
    if (!fileName || typeof fileName !== 'string') {
        return ''
    }

    if (fileName.indexOf('foodcheri') !== -1) {
        return cloudinaryCore.url(fileName)
    }

    return fileName = (/^http/i).test(fileName)
        ? fileName
        : URLS.companyLogoImageUrlPrefix + '/' + fileName
}

/**
 * @function getProductImageUrl
 * @description Function to conditionnaly retrieve the full product image URL
 * @param { string } id - the product id
 * @param { string } fileName - the whole or part of the product image URL
 * @param { string='' } size - an optional size to add to the URL as param
 * @returns { string } The product image URL
 */
export function getProductImageUrl( productId, fileName, size = '' ) {

    if (typeof fileName !== 'string') { return '' }

    // Full URL received
    if ((/^http/i).test(fileName)) { return fileName }

    // Cloudinary id received
    if ((/foodcheri/i).test(fileName) || (/kfc/i).test(fileName)) {
        const options = {
            fileName,
        }
        if (size) {
            size = size.split('x')
            if (size[0]) {
                options.width = size[0]
                options.crop = 'fill'
            }
            if (size[1]) {
                options.height = size[1]
            }
        }

        return cloudinaryCore.url(fileName, options)
    }

    // S3/Cloudfront id received
    return size
        ? URLS.productImageUrlPrefix
            + '/'
            + (fileName ? productId : defaultProductImageId)
            + '/'
            + size
            + '__'
            + (fileName ? fileName : defaultProductImageFile)
        : URLS.productImageUrlPrefix
            + '/'
            + (fileName ? productId : defaultProductImageId)
            + '/'
            + (fileName ? fileName : defaultProductImageFile)
}
