import React from 'react'
import {
    Redirect,
    Route
} from 'react-router-dom'

import SignOut from '../App/SignOut'

export const renderSignOut = () => <SignOut><Redirect to="/" /></SignOut>

export const SignOutRoute = ({ exact=true, path='/logout' }) => (
    <Route
        exact={ !!exact }
        path={ path }
        render={ renderSignOut }
    />
)

export default SignOutRoute
