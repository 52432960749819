import { connect } from 'react-redux'
import React, { Component } from 'react'

import { signIn } from '../../actions/security'

import { classes as CLN } from '../../utils/classNames'

import './SignIn.sass'

export class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                username: '',
                password: '',
            },
            message: '',
            passwordType: 'password'
        }

        this.togglePasswordType = this.togglePasswordType.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange (fieldName, e) {
        this.setState({
            data: {
                ...this.state.data,
                [fieldName]: e.target.value
            }
        })
    }

    handleSubmit (e) {
        e.preventDefault()
        this.props.signIn
            && this.props.signIn(this.state.data)
    }

    togglePasswordType () {
        this.setState({
            passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
        })
    }

    render () {
        const {
            handleChange,
            handleSubmit,
            togglePasswordType
        } = this

        const {
            data: {
                username='',
                password=''
            },
            passwordType
        } = this.state

        const {
            message=''
        } = this.props

        return (
            <section className={ CLN('sign-in') }>
                <h3 className={ CLN(['sign-in', 'title']) }>
                    Me connecter
                </h3>
                <form
                    className={ CLN(['sign-in', 'form']) }
                    onSubmit={ handleSubmit }>
                    <label htmlFor="username" className={ CLN(['sign-in', 'form', 'input'], 'username') }>
                        <input
                            className={ CLN(['sign-in', 'form', 'input', 'field']) }
                            onChange={ e => handleChange('username', e) }
                            name="username"
                            type="text"
                            value={ username }
                            placeholder="E-mail"
                            required={ true }
                            autoComplete="email"
                        />
                    </label>
                    <label htmlFor="password" className={ CLN(['sign-in', 'form', 'input'], 'password') }>
                        <input
                            className={ CLN(['sign-in', 'form', 'input', 'field']) }
                            onChange={ e => handleChange('password', e) }
                            name="password"
                            type={ passwordType || 'password' }
                            value={ password }
                            placeholder="Mot de passe"
                            required={ true }
                            autoComplete="password"
                        />
                        <i
                            onClick={ togglePasswordType }
                            className={ CLN(['sign-in', 'form', 'input', 'icon'], passwordType || 'password') }
                        />
                    </label>
                    <p className={ CLN(['sign-in', 'form', 'message']) }>
                        { message }
                    </p>
                    <button
                        className={ CLN(['sign-in', 'form', 'submit']) }
                        disabled={ !(username && password) }
                        type="submit">
                        Se connecter
                    </button>
                </form>
            </section>
        )
    }
}

export default connect(
    state => ({
        message: (state.message && state.message.signIn) || ''
    }),
    dispatch => ({
        signIn: ({ username, password }) => dispatch(signIn(username, password))
    })
)(SignIn)
