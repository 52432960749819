import { createIcon } from './Icon'

import backIcon from '../../images/icons/back.svg'
import linkBlankIcon from '../../images/icons/link-blank.svg'
import closeIcon from '../../images/icons/close.svg'
import buildingIcon from '../../images/icons/building.svg'
import dashboardIcon from '../../images/icons/dashboard.svg'
import historyIcon from '../../images/icons/history.svg'
import paramsIcon from '../../images/icons/params.svg'
import userIcon from '../../images/icons/user.svg'
import userIcon2 from '../../images/icons/user2.svg'
import teamIcon from '../../images/icons/team.svg'
import infoIcon from '../../images/icons/info.svg'
import increaseIcon from '../../images/icons/increase.svg'
import decreaseIcon from '../../images/icons/decrease.svg'
import flatIcon from '../../images/icons/flat.svg'
import starIcon from '../../images/icons/star.svg'
import starIcon2 from '../../images/icons/star2.svg'
import corianderIcon from '../../images/icons/coriander.svg'
import glutenIcon from '../../images/icons/gluten.svg'
import lactoseIcon from '../../images/icons/lactose.svg'
import hotIcon from '../../images/icons/hot.svg'
import searchIcon from '../../images/icons/search.svg'
import caretIcon from '../../images/icons/caret.svg'
import checkIcon from '../../images/icons/check.svg'


export const BackIcon = createIcon(backIcon, 'BackIcon')
export const LinkBlankIcon = createIcon(linkBlankIcon, 'LinkBlankIcon')
export const CloseIcon = createIcon(closeIcon, 'CloseIcon')
export const BuildingIcon = createIcon(buildingIcon, 'BuildingIcon')
export const DashboardIcon = createIcon(dashboardIcon, 'DashboardIcon')
export const ParamsIcon = createIcon(paramsIcon, 'ParamsIcon')
export const HistoryIcon = createIcon(historyIcon, 'HistoryIcon')
export const UserIcon = createIcon(userIcon, 'UserIcon')
export const UserIcon2 = createIcon(userIcon2, 'UserIcon')
export const TeamIcon = createIcon(teamIcon, 'TeamIcon')
export const InfoIcon = createIcon(infoIcon, 'InfoIcon')
export const IncreaseIcon = createIcon(increaseIcon, 'IncreaseIcon')
export const DecreaseIcon = createIcon(decreaseIcon, 'DecreaseIcon')
export const FlatIcon = createIcon(flatIcon, 'FlatIcon')
export const StarIcon = createIcon(starIcon, 'StarIcon')
export const StarIcon2 = createIcon(starIcon2, 'StarIcon2')
export const GlutenIcon = createIcon(glutenIcon, 'GlutenIcon')
export const LactoseIcon = createIcon(lactoseIcon, 'LactoseIcon')
export const CorianderIcon = createIcon(corianderIcon, 'CorianderIcon')
export const HotIcon = createIcon(hotIcon, 'HotIcon')
export const SearchIcon = createIcon(searchIcon, 'SearchIcon')
export const CaretIcon = createIcon(caretIcon, 'CaretIcon')
export const CheckIcon = createIcon(checkIcon, 'CheckIcon')
