import PropTypes from 'prop-types'
import React from 'react'

import { classes as CLN } from '../../utils/classNames'

import Value from '../Card/Value'

import './ProgressImage.sass'

const ProgressImage = (props={}) => {
    const {
        className='',
        unit='',
        src=''
    } = props

    const value = props.value ? parseInt(props.value) : 0
    const max = props.max ? parseInt(props.max) : 100

    let percentage = parseInt(value / max * 100)
    let title = `[ ${ percentage }% ]: ${ value }/${ max } ${ unit }`

    if (!percentage || percentage === Infinity || isNaN(percentage)) {
        percentage = 0
        title = undefined
    }

    return (
        <div
            title={ title }
            className={ className + ' ' + CLN(['progress-image']) }>
            <progress
                value={ value }
                max={ max }
                className={ CLN(['progress-image', 'progress']) }>
                <Value
                    value={ percentage }
                    type="percentage"
                    baseClass={ CLN(['progress-image', 'progress', 'value']) }
                />
            </progress>
            <div style={{ backgroundSize: '90% ' + Math.max(percentage, 10) + '%' }} className={ CLN(['progress-image', 'display']) }>
                <img className={ CLN(['progress-image', 'display', 'mask']) } src={ src } alt={ percentage + '%' } />
            </div>
        </div>
    )
}

ProgressImage.propTypes = {
    className: PropTypes.string,
    unit: PropTypes.string,
    src: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default React.memo(ProgressImage)
