import PropTypes from 'prop-types'
import React from 'react'

import {
    getReadableDate
} from '../../utils/datetime'

import {
    toPercentageString
} from '../../utils/stringFormats'

import './Tooltip.sass'

const CustomTooltip = props => {
    const {
        valueType='',
        labelType=''
    } = props

    let label = props.label || ''
    let value = props.value || 0

    switch (valueType) {
    case 'percentage':
        value = typeof value === 'number'
            ? toPercentageString(Math.round(value)).replace(',00', '')
            : value + ' %'
        break
    default:
        value = String(value)
    }

    switch (labelType) {
    case 'date':
        label = getReadableDate(label)
        break
    default:
        label = String(label)
    }

    return (
        <div className="chart-tooltip">
            <p className="chart-tooltip_label">{ label }</p>
            <p className="chart-tooltip_value">{ value }</p>
        </div>
    )
}
CustomTooltip.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    labelType: PropTypes.string,
    valueType: PropTypes.string
}

export const DateLabelTooltip = ({ label, payload }) => (
    <CustomTooltip label={ label } value={ (Array.isArray(payload) && payload[0] && payload[0].value) || 0 } labelType="date" />
)

export const PercentageValueTooltip = ({ label, payload }) => (
    <CustomTooltip label={ label } value={ (Array.isArray(payload) && payload[0] && payload[0].value) || 0 } valueType="percentage" />
)

export const RawValueTooltip = ({ label, payload }) => (
    <CustomTooltip label={ label } value={ (Array.isArray(payload) && payload[0] && payload[0].value) || 0 } valueType="raw" />
)

export default CustomTooltip
