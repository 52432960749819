import moment from 'moment'
import React from 'react'

import { DashboardBox } from '../Dashboard'

import Card from '../../Card/Card'
import NoCard from '../../Card/NoCard'

moment.locale('fr')

const OrderAmountRow = (props={}) => {
    const {
        loading=true,
        minHeight='auto'
    } = props

    const current = props.current || {}
    const previous = props.previous || {}

    const orderTotalAmount = {
        currentValue: (current.orders && current.orders.totalAmount) || 0,
        previousValue: (previous.orders && previous.orders.totalAmount) || 0
    }
    const orderDiscountAmount = {
        currentValue: (current.orders && current.orders.discountAmount) || 0,
        previousValue: (previous.orders && previous.orders.discountAmount) || 0
    }

    const companyDiscountAmount = current.orders && current.orders.companyDiscountAmount
        ? {
            currentValue: current.orders.companyDiscountAmount,
            lastValue: (previous.orders && previous.orders.companyDiscountAmount) || 0
        }
        : null

    return (
        <div className="row">
            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }} loading={ loading }>
                    <Card
                        label="Dépense des salariés"
                        info="order-total-amount"
                        type="currency"
                        currentValue={ orderTotalAmount.currentValue }
                        lastValue={ orderTotalAmount.previousValue }
                        className="dashboard_card dashboard_card--order-total-amount"
                    />
                </DashboardBox>
            </div>
            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }} loading={ loading }>
                    {
                        orderDiscountAmount && orderDiscountAmount.currentValue
                            ? <Card
                                label="Remises accordées"
                                info="offer-amount"
                                type="currency"
                                currentValue={ orderDiscountAmount.currentValue }
                                lastValue={ orderDiscountAmount.previousValue }
                                className="dashboard_card dashboard_card--offer-amount"
                            />
                            : <NoCard
                                className="dashboard_card dashboard_card--no-discount-amount"
                                title="Pas de remises accordées"
                                body="Pour mettre cette option en place, contactez notre équipe"
                            />
                    }
                </DashboardBox>
            </div>
            <div className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }} loading={ loading }>
                    {
                        companyDiscountAmount
                            ? <Card
                                label='Subvention employeur'
                                info='discount-amount'
                                type="currency"
                                currentValue={ companyDiscountAmount.currentValue }
                                lastValue={ companyDiscountAmount.previousValue }
                                className='dashboard_card dashboard_card--discount-amount'
                            />
                            : <NoCard
                                className="dashboard_card dashboard_card--no-discount-amount"
                                title="Pas de Subvention Employeur"
                                body="Pour mettre cette option en place, contactez notre équipe"
                            />
                    }
                </DashboardBox>
            </div>
        </div>
    )
}

export default OrderAmountRow
