import moment from 'moment'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React from 'react'

import Delta from './Delta'
import CardInfo from './CardInfo'
import CardHighlight from './CardHighlight'

import { classes as CLN } from '../../utils/classNames'

import './ChartCard.sass'

export const formatDate = (date) => moment(date).format('dddd DD')

const ChartCard = (props = {}) => {
    const {
        className='',
        children=null,
        currentValue='',
        lastValue=null,
        type='raw',
        label='',
        highLightLabel='',
        highLightValue='',
        highLightType='',
        info='',
    } = props

    return (
        <div className={ className + ' chart-card'}>
            <div className={ CLN(['chart-card', 'head']) }>
                <h4 className={ CLN(['chart-card', 'head', 'title'], { 'count': !!currentValue }) }>
                    <span className={ CLN(['chart-card', 'head', 'title', 'count']) }>
                        { currentValue }
                    </span>
                    &nbsp;
                    { label }
                </h4>
                <Delta
                    type={ type }
                    currentValue={ currentValue }
                    lastValue={ lastValue }
                    className={ CLN(['chart-card', 'head', 'last']) } />
                <CardHighlight
                    className={ CLN(['chart-card', 'head', 'highlight']) }
                    label={ highLightLabel }
                    value={ highLightValue }
                    type={ highLightType }
                />
            </div>
            { children }
            <CardInfo info={ info || label } />
        </div>
    )
}

ChartCard.propTypes = {
    className: PropTypes.string,
    currentValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    lastValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    label: PropTypes.string
}

export default ChartCard
