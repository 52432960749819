/* global process */
// This must be the first import:
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'ie-array-find-polyfill'

import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import whyDidYouUpdate from 'why-did-you-update'
// import subscribe from 'redux-actors'

import App from './components/App/App'

import * as serviceWorker from './serviceWorker'

import createStore from './createStore'

import './styles/reset.sass'
import './styles/text.sass'
import './styles/index.sass'

if (process.env.REACT_APP_PERF_DEBUG === 'true') {
    whyDidYouUpdate(React)
}

const store = createStore({})

ReactDOM.render(
    <Provider store={ store }>
        <App dispatch={ store.dispatch } />
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
