import moment from 'moment'
import React, { Fragment } from 'react'

import Lazy from '../../Lazy/Lazy'

import UserRow from './UserRow'
import OrderDetailRow from './OrderDetailRow'
import OrderPreferenceRow from './OrderPreferenceRow'
import OrderAmountRow from './OrderAmountRow'

moment.locale('fr')

const ActivitySection = (props={}) => {

    const {
        current={},
        previous={},
        loading=true
    } = props

    return (
        <Fragment>
            <Lazy height={ 100 }>
                <UserRow
                    current={ current }
                    previous={ previous }
                    loading={ loading }
                    minHeight={ 100 }
                />
            </Lazy>

            <Lazy height={ 350 }>
                <OrderDetailRow
                    current={ current }
                    previous={ previous }
                    loading={ loading }
                    minHeight={ 350 }
                />
            </Lazy>

            <Lazy height={ 280 }>
                <OrderPreferenceRow
                    current={ current }
                    previous={ previous }
                    loading={ loading }
                    minHeight={ 280 }
                />
            </Lazy>

            <Lazy height={ 100 }>
                <OrderAmountRow
                    current={ current }
                    previous={ previous }
                    loading={ loading }
                    minHeight={ 100 }
                />
            </Lazy>
        </Fragment>
    )
}

export default ActivitySection
