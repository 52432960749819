import PropTypes from 'prop-types'
import React from 'react'

import * as Icons from '../Icons'

import { classes as CLN } from '../../utils/classNames'

export const LinkPreview = props => {
    const {
        icon='',
        id='',
        label='',
        to='',
        className='link'
    } = props

    const Icon = (icon && Icons[icon]) || null

    return (
        <div
            className={ CLN(className, 'disabled') }>
            { Icon
                ? <Icon className={ CLN([className, 'icon'], id) } />
                : <i className={ CLN([className, 'icon'], id) } />}
            <span className={ CLN([className, 'label']) }>
                { label || to || '' }
            </span>
            <span className={ CLN([className, 'info']) }>
                bientôt
            </span>
        </div>
    )
}
LinkPreview.propTypes = {
    icon: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    to: PropTypes.string,
    className: PropTypes.string
}

export default React.memo(LinkPreview)
