/**
 * now - helper function to return the current timestamp
 */
export const now = () => (window.performance && window.performance.now()) || Date.now()

/**
 * setTimeout - helper function that set a timeout using window.requestAnimationFrame
 * @arg { function } fn - The function to call after a certain amount of time
 * @arg { number } delay - The delay to respect before calling the function in milliseconds
 * @returns { number } A reference to the timeout set
 */
export const setTimeout = (fn, delay) => {
    const handle = {}
    const start = now()

    function loop() {
        const current = now()
        const delta = current - start

        delta >= delay ? fn.call() : (handle.value = window.requestAnimationFrame(loop))
    }

    handle.value = window.requestAnimationFrame(loop)

    return handle.value
}

/**
 * clearTimeout - helper function to clear a previously set timeout
 * @arg { number } ref - The set timeout reference
 */
export const clearTimeout = (ref) => window.cancelAnimationFrame(ref)
