import PropTypes from 'prop-types'
import React from 'react'

import { classes as CLN } from '../../utils/classNames'
import { getCompanyLogoUrl } from '../../utils/images'

import DEFAULT_COMPANY_LOGO from '../../images/company-default.svg'

export const Head = ({ logo='', name='', className='' }) => (
    <figure className={ CLN(className, { empty: !logo })}>
        <img src={ getCompanyLogoUrl(logo) || DEFAULT_COMPANY_LOGO } alt={ name ||'' } />
        <figcaption>{ name }</figcaption>
    </figure>
)

Head.propTypes = {
    logo: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string
}

export default React.memo(Head)
