import moment from 'moment'
import React from 'react'

import { DashboardBox } from '../Dashboard'

import ChartCard from '../../Card/ChartCard'
import PictureCard from '../../Card/PictureCard'

import BarChart from '../../Charts/Bars'

import { sum, ratio } from '../../../utils/statistics'

import hourImage from '../../../images/hour.svg'
import placeImage from '../../../images/place.svg'

moment.locale('fr')

const OrderPreferenceRow = (props={}) => {
    const {
        loading=true,
        minHeight='auto'
    } = props

    const current = props.current || {}
    const previous = props.previous || {}

    if (current.users && Array.isArray(current.users.detail)) {
        const total = (current.orders && current.orders.cout)
            || current.users.detail
                .reduce((result, day) => sum([result, day.value]), 0)

        current.users.detail = current.users.detail
            .map(day => ({
                ...day,
                value: ratio(day.value, total, true)
            }))
    }

    const userDetail = (current.users
        && current.orders
        && current.users.detail
        && current.users.detail)
        || []

    const outOfSiteOrders = {
        currentValue: current.orders
            ? ratio(current.orders.outOfSite, current.orders.count, true)
            : 0,
        lastValue: previous.orders
            ? ratio(previous.orders.outOfSite, previous.orders.count, true)
            : 0
    }

    const bestTimeslot = (current.orders && current.orders.bestTimeslot) || '--:-- - --:--'

    return (
        <div className="row">
            <div className="col-xs-12 col-lg-8">
                <DashboardBox style={{ minHeight }} loading={ loading }>
                    <ChartCard
                        info="weekdays"
                        className="dashboard_card dashboard_card--weekdays"
                        label="Préférences de jour de livraison">
                        <BarChart
                            data={ userDetail }
                            unit="%"
                            xDataKey="weekday"
                            valueType="percentage"
                            className="order-count_card_chart" />
                    </ChartCard>
                </DashboardBox>
            </div>
            <div style={{ minHeight }} className="col-xs-12 col-lg-4">
                <DashboardBox style={{ minHeight }} loading={ loading }>
                    <PictureCard
                        src={ placeImage }
                        label='Commandes nomades'
                        info='outofsite-orders'
                        type="percentage"
                        currentValue={ outOfSiteOrders.currentValue }
                        lastValue={ outOfSiteOrders.previousValue }
                        className='dashboard_card dashboard_card--outofsite-orders'
                    />
                    <PictureCard
                        src={ hourImage }
                        label='Créneau préféré'
                        info='best-timeslot'
                        currentValue={ bestTimeslot }
                        className='dashboard_card dashboard_card--best-timeslot'
                    />
                </DashboardBox>
            </div>
        </div>
    )
}

export default OrderPreferenceRow
