import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TextInput from '../Inputs/TextInput'
import SelectInput from '../Inputs/SelectInput'

import { openModal, closeModal } from '../../actions/ui'
import { createUser } from '../../actions/users'

import { classes as CLN } from '../../utils/classNames'

import ROLES from '../../data/roles'

import './UserCreate.sass'

const USER_CREATE_FORM_FIELDS = {
    firstName: '',
    lastName: '',
    email: '',
    team: '',
    // companyDiscountType: '',
    role: ''
}

const roles = Object.values(ROLES)

export class UserCreateFormCmp extends Component {
    constructor (props) {
        super(props)

        this.state ={
            values: { ...USER_CREATE_FORM_FIELDS },
            errors: { ...USER_CREATE_FORM_FIELDS },
            maySubmit: false,
            isLoading: false,
            errorForm: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { values } = this.state

        if (this.validateForm(values)) {
            this.setState({ isLoading: true })
            return this.props.createUser && this.props.createUser(values)
                .then((code) => {
                    if (code){
                        this.showErrorForm()
                    } else {
                        this.props.closeModal && this.props.closeModal()
                    }
                })
                .catch(() => {
                    this.setState({ isLoading: false })
                })
        }
    }

    validateForm = (values) => {
        const formValues = { ...values }
        delete formValues.team
        return Object.values(formValues).every(v => !!v)
    }

    showErrorForm = () => {
        this.setState({errorForm: true})
    }

    handleChange = (field, value) => {
        if (field) {

            const values = {
                ...this.state.values,
                [field]: value
            }

            const errors = {
                ...this.state.errors,
                [field]: ''
            }

            const maySubmit = this.validateForm(values)

            this.setState({
                values,
                errors,
                maySubmit
            })
        }
    }

    render () {
        const {
            values={},
            errors={},
            maySubmit=false,
            isLoading=false
        } = this.state

        const {
            handleChange,
            handleSubmit
        } = this

        const {
            account,
            teams=[]
        } = this.props

        if (!account) { return null }

        // const {
        //     teams=[],
        //     companyDiscountTypes=[]
        // } = account

        const selectedTeam = (Array.isArray(teams) && teams.find(({ value }) => value === values.team)) || null
        const selectedRole = (Array.isArray(roles) && roles.find(({ value }) => value === values.role)) || null

        return (
            <form onSubmit={ maySubmit ? handleSubmit : undefined } className={ CLN('user-create-form') }>
                {
                    this.state.errorForm === true
                        ? <p className="text-form-error">Veuillez vérifier les information saisies</p>
                        : ''
                }
                <div className={ CLN(['user-create-form', 'row']) }>
                    <TextInput
                        readOnly={ isLoading }
                        onChange={ handleChange.bind(this, 'firstName') }
                        inline={ true }
                        required={ true }
                        name="firstName"
                        value={ values.firstName }
                        error={ errors.firstName }
                        placeholder="Prénom" />
                    <TextInput
                        readOnly={ isLoading }
                        onChange={ handleChange.bind(this, 'lastName') }
                        inline={ true }
                        required={ true }
                        name="lastName"
                        value={ values.lastName }
                        error={ errors.lastName }
                        placeholder="Nom de famille" />
                </div>
                <div className={ CLN(['user-create-form', 'row']) }>
                    <TextInput
                        readOnly={ isLoading }
                        onChange={ handleChange.bind(this, 'email') }
                        inline={ false }
                        required={ true }
                        name="email"

                        value={ values.email }
                        error={ errors.email }
                        placeholder="E-mail" />
                </div>

                <div className={ CLN(['user-create-form', 'row']) }>
                    <SelectInput
                        readOnly={ isLoading }
                        name="team"
                        items={ teams }
                        error={ errors.lastName }
                        selected={ selectedTeam }
                        placeholder="Sélectionner une équipe"
                        onChange={ handleChange.bind(this, 'team') } />
                </div>
                {/*
                <div className={ CLN(['user-create-form', 'row']) }>
                    <SelectInput
                        readOnly={ isLoading }
                        name="companyDiscountType"
                        items={ companyDiscountTypes }
                        selected={ companyDiscountTypes.find(({ value }) => value === values.companyDiscountType) || null }
                        placeholder="Sélectionner une subvention"
                        onChange={ handleChange.bind(this, 'companyDiscountType') } />
                </div>
                */}
                <div className={ CLN(['user-create-form', 'row']) }>
                    <SelectInput
                        readOnly={ isLoading }
                        name="role"
                        items={ Object.values(roles).filter(r => r.showOnUserSelect === true) }
                        selected={ selectedRole }
                        placeholder="Sélectionner un rôle"
                        onChange={ handleChange.bind(this, 'role') } />
                </div>
                <div className={ CLN(['user-create-form', 'row']) }>
                    <p className={ CLN(['user-create-form', 'row']) }>
                        L'utilisateur créé recevra un email de bienvenue
                    </p>
                </div>
                <button
                    className={ CLN(['user-create-form', 'submit']) }
                    type="submit"
                    disabled={ isLoading || maySubmit === false }>
                    Créer le compte
                </button>
            </form>
        )
    }
}

export const UserCreateForm = connect(
    state => ({
        account: state.account || {},
        teams: (state.teams && state.teams.teamListData) || []
    }),
    dispatch => ({
        createUser: data => dispatch(createUser(data)),
        closeModal: () => dispatch(closeModal())
    })
)(UserCreateFormCmp)

export const USER_FORM_MODAL_CONFIG = {
    title: 'Nouvel Utilisateur',
    body: <UserCreateForm />,
    hasConfirmButton: false,
    id: 'user-create'
}

export const UserCreate = (props={}) => {
    const {
        className='user-create-button',
        openModal
    } = props

    return (
        <button
            type="button"
            className={ CLN(className) }
            onClick={ openModal }>
            Nouvel utilisateur
        </button>
    )
}

UserCreate.propTypes = {
    className: PropTypes.string,
    openModal: PropTypes.func,
    account: PropTypes.object
}

export default connect(
    () => ({}),
    dispatch => ({
        openModal: () => dispatch(openModal(USER_FORM_MODAL_CONFIG))
    })
)(UserCreate)
