import PropTypes from 'prop-types'
import React from 'react'

import Value from './Value'
import {
    IncreaseIcon,
    DecreaseIcon,
    FlatIcon
} from '../Icons'

import { toPercentageString } from '../../utils/stringFormats'
import { classes as CLN } from '../../utils/classNames'

import './Delta.sass'

const Delta = (props) => {
    const {
        className='last-value',
        type='raw'
    } = props

    if (!props.lastValue) {
        return null
    }

    const lastValue = parseFloat(props.lastValue)
    const currentValue = parseFloat(props.currentValue || 0)

    if (isNaN(lastValue) || isNaN(currentValue)) {
        return null
    }

    let deltaType = null
    let DeltaIcon = () => null
    let delta = currentValue - lastValue
    delta = Math.round(delta / lastValue * 100)

    switch (true) {
    case delta > 0:
        delta = toPercentageString(Math.abs(delta)).replace(',00', '')
        deltaType = 'increase'
        DeltaIcon = IncreaseIcon
        break
    case delta < 0:
        delta = toPercentageString(Math.abs(delta)).replace(',00', '')
        deltaType = 'decrease'
        DeltaIcon = DecreaseIcon
        break
    case delta === 0:
    case delta === Infinity:
        delta = ''
        deltaType = 'flat'
        DeltaIcon = FlatIcon
        break
    default:
        delta = null
    }

    if (!deltaType || deltaType === 'flat') { return null }

    return (
        <div className={ className + ' last-value' }>
            <p className={ CLN([ 'last value', 'delta' ], deltaType)}>
                <DeltaIcon />
                { delta }
            </p>
            <div className={ CLN(['last-value', 'tooltip'])}>
                <h6 className={ CLN(['last-value', 'label'])}>
                    Période précédente
                </h6>
                <p className={ CLN(['last-value', 'value'])}>
                    <Value
                        type={ type }
                        value={ lastValue }
                        baseClass={ CLN(['last-value', 'value'])} />
                </p>
            </div>
        </div>

    )
}

Delta.propTypes = {
    currentValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    lastValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    className: PropTypes.string,
    type: PropTypes.string
}

export default React.memo(Delta)
