import PropTypes from 'prop-types'
import React, { Component } from 'react'

import UserCreate from './UserCreate'
import UserUpdate from './UserUpdate'
import { SearchIcon, CloseIcon } from '../Icons'

import { classes as CLN } from '../../utils/classNames'

import './UserHead.sass'

class UserHead extends Component {

    state = {
        searchState: {
            focused: false,
            empty: true
        }
    }

    handleSearchFocus = () => {
        this.setState({
            searchState: {
                ...this.state.searchState,
                focused: true
            }
        })
    }

    handleSearchBlur = () => {
        this.setState({
            searchState: {
                ...this.state.searchState,
                focused: false
            }
        })
    }

    handleSearchChange = e => {
        this.setState({
            searchState: {
                ...this.state.searchState,
                empty: !(e.target && e.target.value)
            }
        })
        this.props.filterData && this.props.filterData(e)
    }

    render () {
        const {
            selection=[],
            hasSelection=false,
            toggleAll,
            searchTerm
        } = this.props

        const {
            searchState={
                focused: false,
                empty: true
            }
        } = this.state

        const {
            handleSearchBlur,
            handleSearchFocus,
            handleSearchChange
        } = this

        return (
            <div className={ CLN(['users', 'head']) }>
                <h2 className={ CLN(['users', 'head', 'title']) }>
                    Utilisateurs
                    <small className={ CLN(['users', 'head', 'title', 'count'], { hide: !hasSelection }) }>
                        { selection.length } sélectionné{ selection.length > 1 ? 's' : '' }
                        <i onClick={ () => toggleAll(false) }><CloseIcon /></i>
                    </small>
                </h2>
                <div className={ CLN(['users', 'head', 'controls'], { batch: hasSelection }) }>
                    {
                        hasSelection
                            ? <UserUpdate
                                selection={ selection }
                                className={ CLN(['users', 'head', 'controls', 'user-update']) } />
                            : <UserCreate className={ CLN(['users', 'head', 'controls', 'user-create']) } />
                    }
                </div>
                <label
                    htmlFor="userSearch"
                    className={ CLN(['users', 'head', 'search'], { empty: searchState.empty && !searchState.focused }) }>

                    <SearchIcon
                        className={ CLN(['users', 'head', 'search', 'icon']) }
                    />

                    <input
                        className={ CLN(['users', 'head', 'search', 'field']) }
                        type="text"
                        placeholder="Rechercher un utilisateur, un e-mail..."
                        value={ searchTerm }
                        onChange={ handleSearchChange }
                        onFocus={ handleSearchFocus }
                        onBlur={ handleSearchBlur }
                        name="userSearch"
                    />

                    <span
                        className={ CLN(['users', 'head', 'search', 'close']) }
                        onClick={ handleSearchChange }>
                        <CloseIcon />
                    </span>

                </label>
            </div>
        )
    }
}

UserHead.propTypes = {
    selection: PropTypes.array,
    hasSelection: PropTypes.bool,
    toggleAll: PropTypes.func,
    searchTerm: PropTypes.string,
    filterData: PropTypes.func
}

export default UserHead
