import PropTypes from 'prop-types'
import React from 'react'

import { classes as CLN } from '../../utils/classNames'

import Value from './Value'
import Delta from './Delta'
import CardInfo from './CardInfo'

import './PictureCard.sass'

const PictureCard = (props) => {
    const {
        label = '',
        info='',
        currentValue = 0,
        lastValue = null,
        className='',
        src='',
        type='raw',
        symbol=''
    } = props

    return (
        <div className={ ('picture-card ' + className).trim() }>
            <img src={ src } alt={ label } className={ CLN(['picture-card', 'image'])} />
            <div className={ CLN(['picture-card', 'current'])}>

                <p className={ CLN(['picture-card', 'current', 'value'])}>
                    <Value
                        type={ type }
                        value={ currentValue }
                        symbol={ symbol }
                        baseClass={ CLN(['picture-card', 'current', 'value'])}
                    />
                </p>

                <h6 className={ CLN(['picture-card', 'current', 'label'])}>
                    { label }
                </h6>

            </div>
            <Delta
                type={ type }
                currentValue={ currentValue }
                lastValue={ lastValue }
                className={ CLN(['picture-card', 'last']) } />
            <CardInfo info={ info || label } />
        </div>
    )
}

PictureCard.propTypes = {
    label: PropTypes.string,
    info: PropTypes.string,
    currentValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    lastValue:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.string
}

export default React.memo(PictureCard)
