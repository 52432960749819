import PropTypes from 'prop-types'
import React from 'react'

import { classes as CLN } from '../../utils/classNames'

import Value from './Value'
import Delta from './Delta'
import CardInfo from './CardInfo'

import './Card.sass'

const Card = (props) => {
    const {
        label = '',
        info='',
        currentValue = 0,
        lastValue = null,
        className='',
        type='raw',
        children=null,
        footer=null
    } = props

    return (
        <div className={ ('card ' + className).trim() }>
            <div className={ CLN(['card', 'head'])}>
                <div className={ CLN(['card', 'head', 'current'])}>
                    <h6 className={ CLN(['card', 'head', 'current', 'label'])}>
                        { label }
                    </h6>
                    <p className={ CLN(['card', 'head', 'current', 'value'])}>
                        <Value
                            type={ type }
                            value={ currentValue }
                            baseClass={ CLN(['card', 'head', 'current', 'value'])}
                        />
                    </p>
                </div>
                <Delta
                    type={ type }
                    currentValue={ currentValue }
                    lastValue={ lastValue }
                    className={ CLN(['card', 'head', 'last']) } />
                <CardInfo info={ info || label } />
            </div>
            <div className={ CLN(['card', 'body'])}>
                { children }
            </div>
            <div className={ CLN(['card', 'footer'])}>
                { footer }
            </div>
        </div>
    )
}

Card.propTypes = {
    label: PropTypes.string,
    info: PropTypes.string,
    currentValue: PropTypes.number,
    lastValue: PropTypes.number,
    className: PropTypes.string
}

export default Card
